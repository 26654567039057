import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export const GET_USERS_URL = `${API_URL}api/v1/auth/admin/user`
export const GET_SEARCH_USERS_URL = `${API_URL}api/v1/auth/admin/user`
export const CHANGE_STATUS_USER_URL = `${API_URL}api/v1/auth/admin/user/status`
export const BLOCK_USER_URL = `${API_URL}api/v1/auth/admin/user/block`
export const CREATE_USER_URL = `${API_URL}api/v1/auth/admin/user`
export const EDIT_USER_URL = `${API_URL}api/v1/auth/admin/user`
export const DELETE_USER_URL = `${API_URL}api/v1/auth/admin/user`

interface ListType {
  count?: any
  data: any
}

// Get USers Data
export async function getUsersData(startIndex: number, limitNumber: number) {
    try {
      let usersData: ListType = await axios.get(`${GET_USERS_URL}?skip=${startIndex}&limit=${limitNumber}`)
      return usersData
    } catch (e) {
      throw e
    }
  }

  export async function getSearchUsersData(startIndex: number, limitNumber: number,searchText: string) {
    try {
      let usersData: ListType  = await axios.get(`${GET_USERS_URL}?skip=${startIndex}&limit=${limitNumber}&q=${searchText}`)
      return usersData
    } catch (e) {
      throw e
    }
  }  

  // Change Status User Data
export async function changeStatusUsersData(id: string, flag: boolean) {
  try {
    let userData = await axios.put(`${CHANGE_STATUS_USER_URL}`, {
      id,
      is_active: flag,
    })
    return userData
  } catch (e) {
    throw e
  }
}

export async function changeBlockToogleData(id: string, flag: boolean) {
  try {
    let userData = await axios.put(`${BLOCK_USER_URL}`, {
      id,
      is_blocked: flag,
    })
    return userData
  } catch (e) {
    throw e
  }
}

// Create User Data
export async function createUserData(userObject: any) {
  try {
    let createUserData = await axios.post(`${CREATE_USER_URL}`, {
      ...userObject,
    })
    return createUserData
  } catch (e) {
    throw e
  }
}

// Edit User Data
export async function editUserData(userObject: any) {
  try {
    let editUserData = await axios.put(`${EDIT_USER_URL}`, {
      ...userObject,
    })
    return editUserData
  } catch (e) {
    throw e
  }
}

// Delete User Data
export async function deleteUserData(id: string) {
  try {
    let deleteUserData = await axios.delete(`${DELETE_USER_URL}?id=${id}`)
    return deleteUserData
  } catch (e) {
    throw e
  }
}