/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as buyRequest from '../redux/BuyRequestRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons'
import {
  editBuyRequestData,
  getBuyRequestData,
  getSearchBuyRequestData,
} from '../redux/BuyRequestCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
// import {EditBuyRequestForm} from './EditBuyRequestForm'
// import {any} from './CreateBuyRequestForm'
import {Tag} from 'antd'
import {returnPriceInThousandFormatWithCurrencySign, returnStockId} from '../../../comman/helper'
import {EditBuyRequestForm} from './EditBuyRequestForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  tabkey: any
  buyRequestData: any
  buyRequestDataTotal: number
  buyRequestLoading: boolean
  setListBuyRequestData: (buyRequestData: any) => void
  setListBuyRequestDataTotal: (buyRequestDataTotal: number) => void
  setBuyRequestLoading: (buyRequestLoading: boolean) => void
  history?: History<LocationState>
  loginUser: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  buyRequestSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListBuyRequestData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      buyRequestSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListBuyRequestData(pagination)
    )
  }

  public getListBuyRequestData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listBuyRequestSearchData(start, end)
        } else {
          this.listBuyRequestData(start, end)
        }
      }
    )
  }

  public listBuyRequestData = async (start: number, end: any) => {
    const {setBuyRequestLoading} = this.props
    try {
      const {setListBuyRequestData, setListBuyRequestDataTotal, loginUser, tabkey} = this.props
      await setBuyRequestLoading(true)
      let status = ''
      if (loginUser?.role === 'admin') {
        status = 'PENDING'
      }
      let buyRequestData = await getBuyRequestData(start, end, tabkey)
      if (buyRequestData.data) {
        await setListBuyRequestData(buyRequestData.data)
        await setListBuyRequestDataTotal(buyRequestData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setBuyRequestLoading(false)
    }
  }

  public listBuyRequestSearchData = async (start: number, end: any) => {
    const {setBuyRequestLoading, tabkey} = this.props
    try {
      const {setListBuyRequestData, setListBuyRequestDataTotal} = this.props
      const {searchText} = this.state

      await setBuyRequestLoading(true)
      let buyRequestData = await getSearchBuyRequestData(start, end, tabkey, searchText)
      if (buyRequestData.data) {
        await setListBuyRequestData(buyRequestData.data)
        await setListBuyRequestDataTotal(buyRequestData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setBuyRequestLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listBuyRequestSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onEditSubmit = async (values: any, buyRequestId: string, editStatus: string) => {
    const {setBuyRequestLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setBuyRequestLoading(true)
      let editResponse = await editBuyRequestData(values, buyRequestId, editStatus)

      if (editResponse && editResponse.status == 200) {
        Toaster({
          type: 'success',
          title: 'BUY REQUEST',
          description: 'Updated SuccessFully',
        })
      }
      if (searchText != null && searchText !== '') {
        this.listBuyRequestSearchData(startIndex, limitNumber)
        return
      }
      this.listBuyRequestData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setBuyRequestLoading(false)
    }
  }

  public navigateToEditPage = async (item: any) => {
    const {history} = this.props
    history.push(`${process.env.PUBLIC_URL}/buy-request/preview/` + item.id)
  }

  public showEditModal = async (item: any) => {
    this.setState({
      buyRequestSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      buyRequestSelectedData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public vendorColumns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Full Name',
      key: 'Full Name',
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item?.user?.first_name && item?.user?.last_name
                ? `${item.user.first_name} ${item.user.last_name}`
                : item.user.first_name
                ? item.user.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Status',
      key: 'status',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.status ? (
              <Tag
                color={
                  config.statusTagColor[item.status]
                    ? config.statusTagColor[item.status]
                    : 'default'
                }
              >
                {item.status}
              </Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Quantity',
      key: 'stock_ids',
      render: (text: any, item: any, index: number) => {
        return <div> {item.stock_ids ? item.stock_ids.length : '-'}</div>
      },
    },
    {
      title: 'Total Cts',
      key: 'total_cts',
      render: (text: any, item: any, index: number) => {
        return <div> {item.total_cts ? item.total_cts.toFixed(2) : '0'}</div>
      },
    },
    {
      title: 'Price/Carat',
      key: 'price_per_carat',
      render: (text: any, item: any, index: number) => {
        return <div> {item.price_per_carat ? returnPriceInThousandFormatWithCurrencySign(item.price_per_carat.toFixed(2)) : '0'}</div>
      },
    },
    {
      title: 'Shipment Price',
      key: 'shipment_price',
      render: (text: any, item: any, index: number) => {
        return <div> {item.shipment_price ? returnPriceInThousandFormatWithCurrencySign(item.shipment_price.toFixed(2)) : '0'}</div>
      },
    },
    {
      title: 'Total Amount',
      key: 'amount',
      render: (text: any, item: any, index: number) => {
        return <div> {item.amount ? returnPriceInThousandFormatWithCurrencySign(item.amount.toFixed(2)) : '0'}</div>
      },
    },
    {
      title: 'Grand Amount',
      key: 'grand_total',
      render: (text: any, item: any, index: number) => {
        return <div> {item.grand_total ? returnPriceInThousandFormatWithCurrencySign(item.grand_total.toFixed(2)) : '0'}</div>
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Preview'>
                <Button
                  shape='circle'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    // this.showEditModal(item)
                    this.navigateToEditPage(item)
                  }}
                />
              </Tooltip>
              {/* )} */}
            </div>
          </div>
        )
      },
    },
  ]

  public adminColumns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Full Name',
      key: 'Full Name',
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item?.user?.first_name && item?.user?.last_name
                ? `${item.user.first_name} ${item.user.last_name}`
                : item.user.first_name
                ? item.user.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Email',
      key: 'email',
      render: (text: any, item: any, index: number) => {
        return <div>{item?.user ? (item?.user?.email ? item.user.email : '-') : '-'}</div>
      },
    },
    // {
    //   title: 'Stock Ids',
    //   key: 'stock_ids',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         {item?.stock_diamonds
    //           ? item?.stock_diamonds
    //               .map((stockItem: any) => returnStockId(stockItem.stock_ids))
    //               .join(', ')
    //           : '-'}
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Status',
      key: 'status',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.status ? (
              <Tag
                color={
                  config.statusTagColor[item.status]
                    ? config.statusTagColor[item.status]
                    : 'default'
                }
              >
                {item.status}
              </Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Quantity',
      key: 'stock_ids',
      render: (text: any, item: any, index: number) => {
        return <div> {item.stock_ids ? item.stock_ids.length : '0'}</div>
      },
    },
    {
      title: 'Total Cts',
      key: 'total_cts',
      render: (text: any, item: any, index: number) => {
        return <div> {item.total_cts ? item.total_cts.toFixed(2) : '0'}</div>
      },
    },
    {
      title: 'Price/Carat',
      key: 'price_per_carat',
      render: (text: any, item: any, index: number) => {
        return <div> {item.price_per_carat ? returnPriceInThousandFormatWithCurrencySign(item.price_per_carat.toFixed(2)) : '0'}</div>
      },
    },
    {
      title: 'Shipment Price',
      key: 'shipment_price',
      render: (text: any, item: any, index: number) => {
        return <div> {item.shipment_price ? returnPriceInThousandFormatWithCurrencySign(item.shipment_price.toFixed(2)) : '0'}</div>
      },
    },
    {
      title: 'Total Amount',
      key: 'amount',
      render: (text: any, item: any, index: number) => {
        return <div> {item.amount ? returnPriceInThousandFormatWithCurrencySign(item.amount.toFixed(2)) : '0'}</div>
      },
    },
    {
      title: 'Grand Amount',
      key: 'grand_total',
      render: (text: any, item: any, index: number) => {
        return <div> {item.grand_total ? returnPriceInThousandFormatWithCurrencySign(item.grand_total.toFixed(2)) : '0'}</div>
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            {this.props.tabkey == 'pending' && (
              <div style={{float: 'left', marginRight: '5px'}}>
                <Tooltip placement='bottom' title='Preview'>
                  <Button
                    shape='circle'
                    icon={<EyeOutlined />}
                    onClick={() => {
                      // this.showEditModal(item)
                      this.navigateToEditPage(item)
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        )
      },
    },
  ]

  public render() {
    const {buyRequestData, buyRequestDataTotal, buyRequestLoading, loginUser} = this.props
    const {buyRequestSelectedData} = this.state

    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div style={{overflowX: 'auto'}}>
              <ListTable
                rowKey={(record: {_id: any}) => record._id}
                onChange={this.handleTableChange}
                onPaginationChange={(e: any) => {
                  if (e - 1) {
                    this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                    return
                  }

                  this.counter = 0
                  this.tempCounter = 0
                }}
                column={loginUser?.role === 'vendor' ? this.vendorColumns : this.adminColumns}
                paginationConfig={{
                  total: buyRequestDataTotal,
                  showSizeChanger: true,
                  pageSize: this.state.pagination.pageSize,
                  current: this.state.pagination.current,
                }}
                isLoading={buyRequestLoading}
                dataSource={buyRequestData}
                counter={this.counter}
                tempCounter={this.tempCounter}
                // isScroll={true}
                // scrollWidth={1200}
              />
              <div>
                {/* <CommanModal
                  show={buyRequestSelectedData ? true : false}
                  handleClose={() => {
                    this.closeEditModal()
                  }}
                  modalSize={'modal-xl'}
                  title={
                    loginUser?.role === 'super_admin' || loginUser?.role === 'sub_admin'
                      ? 'Edit Buy Request'
                      : 'View Buy Request'
                  }
                  submitText={'Save Changes'}
                  isScrollable={true}
                >
                  <div>
                    {buyRequestSelectedData && (
                      <EditBuyRequestForm
                        data={buyRequestSelectedData}
                        loginUserData={loginUser}
                        onSubmit={(values: any, buyRequestId: string,editStatus: string) => {
                          this.onEditSubmit(values, buyRequestId,editStatus)
                        }}
                        handleCancel={() => {
                          this.closeEditModal()
                        }}
                      />
                    )}
                  </div>
                </CommanModal> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  buyRequestData: state.buyRequest.buyRequestData,
  buyRequestDataTotal: state.buyRequest.buyRequestDataTotal,
  buyRequestLoading: state.buyRequest.buyRequestLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListBuyRequestData: (buyRequestData: any) => {
      dispatch(buyRequest.actions.setBuyRequestData(buyRequestData))
    },
    setListBuyRequestDataTotal: (buyRequestDataTotal: number) => {
      dispatch(buyRequest.actions.setBuyRequestDataTotal(buyRequestDataTotal))
    },
    setBuyRequestLoading: (buyRequestLoading: boolean) => {
      dispatch(buyRequest.actions.setBuyRequestLoading(buyRequestLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListBuyRequestData))
