import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_RETURN_ORDER_URL = `${API_URL}api/v1/auth/admin/return-order`
export const RETURN_ORDER_STATUS_URL = `${API_URL}api/v1/auth/admin/return-order-action`
export const VENDOR_RETURN_ORDER_STATUS_URL = `${API_URL}api/v1/auth/admin/return-order-status`

interface ListType {
  count?: any
  data: any
}

// Get Order Data
export async function getReturnOrderData(startIndex: number, limitNumber: number, status?: string,role?: string) {
  try {
    let getOrderAPIPath = ''
    if (status == undefined && status == '') {
      return {
        data: []
      }
    }
     else if (status == 'Pending') {
      getOrderAPIPath = `${GET_RETURN_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&is_action_taken=${false}&is_accepted=${false}&list_vendor_stocks=${false}`
    }
    else if(status == "Accepted") {
      getOrderAPIPath = `${GET_RETURN_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&is_action_taken=${true}&is_accepted=${true}&list_vendor_stocks=${false}`
    }
    else if(status == "Rejected") {
      getOrderAPIPath = `${GET_RETURN_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&is_action_taken=${true}&is_accepted=${false}&list_vendor_stocks=${false}`
    }
    else if(status == "Vendor") {
      getOrderAPIPath = `${GET_RETURN_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&is_action_taken=&is_accepted=${role && role !== "vendor" ? true : ''}&list_vendor_stocks=${true}`
    }
    let orderData: ListType = await axios.get(getOrderAPIPath)
    // let orderData: ListType = await axios.get(`${GET_RETURN_ORDER_URL}`)
    return orderData
  } catch (e) {
    throw e
  }
}

export async function returnOrderActionApi(returnOrderActionObj: any) {
  try {
    let returnOrderData = await axios.put(`${RETURN_ORDER_STATUS_URL}`, {
      ...returnOrderActionObj
    })
    return returnOrderData
  } catch (e) {
    throw e
  }
}

export async function changeReturnOrderStausDataApi(values: any) {
  let statusData = await axios.put(`${VENDOR_RETURN_ORDER_STATUS_URL}`, {
    ...values,
  })
  return statusData
}