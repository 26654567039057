/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getBannerData} from '../redux/BannerCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as banner from '../redux/BannerRedux'
import ListBannerData from './ListBannerData'
import {RootState} from '../../../../setup'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const callBannerData = async () => {
    try {
      let bannerData
      bannerData = await getBannerData(0, 10)

      if (bannerData.rows) {
        await dispatch(banner.actions.setBannerLoading(true))
        // once banner data set then loading will automatically off using saga middleware
        await dispatch(banner.actions.setBannerData(bannerData.rows))
        await dispatch(banner.actions.setBannerDataTotal(bannerData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callBannerData()
  }, [])

  return (
    <div>
      <ListBannerData />
    </div>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/banner/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/banner/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.BANNER'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
