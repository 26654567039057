/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {CreateWrapper} from './containers/CreateUser'
import {ListWrapper} from './containers/List'
// import {PreviewWrapper} from './containers/Preview'

export function UsersPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/users/list' exact={true} component={ListWrapper} />
        {/* <Route path='/users/create' exact={true} component={CreateWrapper} /> */}
        <Redirect from='/users' exact={true} to='/users/list' />
        <Redirect to='/users/list' />
      </Switch>
    </div>
  )
}
