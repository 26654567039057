import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {Modal, Upload, Button} from 'antd'
import styled from 'styled-components'
import {InboxOutlined, PictureOutlined} from '@ant-design/icons'
import {message} from 'antd'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {Spin} from 'antd'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const Dragger = Upload.Dragger

const StyledDragger = styled(Dragger)`
  & .ant-upload {
    margin-bottom: 15px;
  }

  & .ant-upload-drag {
    margin-bottom: 15px;
  }
  & .ant-upload-list-item-actions {
    display: none;
  }
`

type Props = {
  onUpload: (
    fileNames: Array<string>,
    fileObjects: Array<any>,
    fileObjectsWithType: Array<Object>
  ) => void
  name: string
  input: {
    value?: boolean
    id: string
    name: string
    action: string
    videoAction?: string
    headers: {
      authorization: string
    }
    accept: string
    acceptMimeTypes: Array<string>
    mediaType: string
    mediaModuleType?: string
  }
  disabled?: boolean
}

const UploadMultipleImages = forwardRef(({onUpload, input, name, disabled = false}: Props, ref) => {
  const [fileList, setFileList] = useState<any[]>([])
  const [visible, setVisible] = useState(false)
  const [uploading, setUploading] = useState(false)

  const show = () => {
    setVisible(true)
  }

  const beforeUpload = (file: any) => {
    const isValid = input.acceptMimeTypes?.includes(file.type)
    if (!isValid) {
      // message.error('Please select valid File Type')
    }
    return false // Prevent automatic upload
  }

  const onHandleChangeUpload = (info: any) => {
    setFileList(info.fileList)
  }

  const uploadFiles = async () => {
    setUploading(true)
    const uploadedFiles: any[] = []
    const fileNames: string[] = []
    for (const file of fileList) {
      const formData = new FormData()
      formData.append('file', file.originFileObj)

      // Determine the action URL based on file type
      const actionUrl =
        file.type.startsWith('video') && input.videoAction ? input.videoAction : input.action

      if (!actionUrl) {
        continue
      }

      try {
        const response = await fetch(actionUrl, {
          method: 'POST',
          headers: input.headers,
          body: formData,
        })
        const result = await response.json()
        if (result.code === 'ERROR_IN_UPLOAD') {
          Toaster({
            type: 'error',
            title: 'Upload',
            description: result.message,
          })
        } else {
          Toaster({
            type: 'success',
            title: 'Upload',
            description: `${file.name} file uploaded successfully.`,
          })
          uploadedFiles.push({
            file: result.file,
            type: file.type.startsWith('video') ? 'video' : 'image',
          })
          fileNames.push(result.file)
        }
      } catch (error) {
        Toaster({
          type: 'error',
          title: 'Upload',
          description: `${file.name} file upload failed`,
        })
      }
    }
    setUploading(false)
    onUpload(fileNames, fileList, uploadedFiles)
    setVisible(false)
  }

  const verifyDoc = () => {
    if (fileList.length <= 0) {
      message.error('Please select a valid file')
      return
    }

    uploadFiles()
  }

  const handleCancel = () => {
    hide()
  }

  const hide = () => {
    setVisible(false)
    setFileList([])
    onUpload([], [], [])
  }

  return (
    <>
      <div className='row'>
        <div
          className='col m-2 d-flex justify-content-center align-items-center border rounded position-relative'
          style={{height: '200px', cursor: 'pointer'}}
          onClick={() => show()}
        >
          <img
            width='100px'
            height='100px'
            src={toAbsoluteUrl('/media/avatars/upload_image.jpg')}
            alt={'Upload Image'}
          />
          <span className='position-absolute bottom-0 fw-bold'>UPLOAD FILES</span>
        </div>
      </div>
      {visible && (
        <Modal width='600px' visible={visible} onCancel={handleCancel} footer={null}>
          <div style={{marginTop: '20px'}}>
            <Spin spinning={uploading} tip='Uploading...'>
              <StyledDragger
                className='clearfix'
                beforeUpload={beforeUpload}
                onChange={onHandleChangeUpload}
                multiple={true}
                {...input} // input props without action
              >
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>

                <p className='ant-upload-text'>Click or drag file to this area to upload</p>

                <p className='ant-upload-hint' style={{marginBottom: '10px'}}>
                  Support for a single or bulk upload. Strictly prohibit from uploading company data
                  or other band files
                </p>
              </StyledDragger>
              <Button style={{marginTop: '50px'}} onClick={verifyDoc}>
                Upload
              </Button>
            </Spin>
          </div>
        </Modal>
      )}
    </>
  )
})

export {UploadMultipleImages}
