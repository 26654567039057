import React from 'react'
import {FileImageOutlined} from '@ant-design/icons'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {UploadMultipleImages} from './UploadMultipleImages'
import { config } from '../../../comman/constants'

const UploadMediaModal = ({socket,onSubmitMedia}: any) => {
  return (
        <UploadMultipleImages
          name='file'
          input={{
            id: 'import-images',
            name: config.chatUploadKey,
            action: config.chatImageUploadPath,
            videoAction: config.chatVideoUploadPath,
            headers: {
              authorization: 'authorized-text',
            },
            accept: config.chatfileExtensionsForDropZone,
            acceptMimeTypes: config.chatfileExtensionsMimeTypes,
            mediaType: config.chatUploadKey,
            mediaModuleType: config.chatUploadKey,
          }}
          onUpload={(fileNames, fileList, uploadedFiles) => {
            console.log("upload data",uploadedFiles)
            onSubmitMedia(uploadedFiles)
          }}
        />
  )
}

export default UploadMediaModal
