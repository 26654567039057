import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setKycData: 'SET_KYC_DATA',
  setKycDataTotal: 'SET_KYC_DATA_TOTAL',
  setKycLoading: 'SET_KYC_LOADER'
}

const initialOrderState: IKycState = {
  kycData: undefined,
  kycDataTotal: undefined,
  kycLoading: false
}

export interface IKycState {
  kycData?: any
  kycDataTotal?: number
  kycLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'kyc', whitelist: ['kycData', 'kycDataTotal']},
    (state: IKycState = initialOrderState, action: ActionWithPayload<IKycState>) => {
    switch (action.type) {
      case actionTypes.setKycData: {
        const kycData = action.payload?.kycData
        return {...state, kycData}
      }
            
      case actionTypes.setKycDataTotal: {
        const kycDataTotal = action.payload?.kycDataTotal
        return {...state, kycDataTotal}
      }
        
      case actionTypes.setKycLoading: {
        const kycLoading = action.payload?.kycLoading
        return {...state, kycLoading}
      }


      default:
        return state
    }
  }
)

export const actions = {
  setKycData: (kycData: Array<any>) => ({type: actionTypes.setKycData, payload: {kycData : kycData}}),
  setKycDataTotal: (kycDataTotal: number) => ({ type: actionTypes.setKycDataTotal, payload: { kycDataTotal } }),
  setKycLoading: (kycLoading: boolean) => ({type: actionTypes.setKycLoading, payload: {kycLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setKycData, function* loginSaga() {
    yield put(actions.setKycLoading(false))
  })
}
