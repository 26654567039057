import React from 'react'
import axios from 'axios'
import {UserModel} from '../../../modules/auth/models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_BUY_REQUEST_URL = `${API_URL}api/v1/auth/buy-request`
export const SEARCH_BUY_REQUEST_URL = `${API_URL}v1/auth/filter-buy-request/`
export const CREATE_BUY_REQUEST_URL = `${API_URL}api/v1/auth/buy-request/`
export const UPDATE_BUY_REQUEST_URL = `${API_URL}api/v1/auth/buy-request/`
export const DELETE_BUY_REQUEST_URL = `${API_URL}v1/auth/buy-request/`
export const CHANGE_STATUS_BUY_REQUEST_URL = `${API_URL}v1/auth/buy-request/`
export const GET_BUY_REQUEST_STOCK_DETAILS = `${API_URL}api/v1/auth/buy-request-details`

interface authType {
  data: UserModel
}

interface ListType {
  count?: any
  data: any
}
// Get BuyRequest Data
export async function getBuyRequestData(startIndex: number, limitNumber: number, status?: string) {
  try {
    let getBuyReqAPIPath = ''
    console.log(status,"api")
    if (status !== undefined && status !== '') {
      getBuyReqAPIPath = `${GET_BUY_REQUEST_URL}?skip=${startIndex}&limit=${limitNumber}&status=${status}`
    } else {
      getBuyReqAPIPath = `${GET_BUY_REQUEST_URL}?skip=${startIndex}&limit=${limitNumber}&status=pending`
    }
    let buyRequestData: ListType = await axios.get(getBuyReqAPIPath)
    // let buyRequestData: ListType = await axios.get(`${GET_BUY_REQUEST_URL}`)
    return buyRequestData
  } catch (e) {
    throw e
  }
}

// Search BuyRequest Data
export async function getSearchBuyRequestData(
  startIndex: number,
  limitNumber: number,
  status: any,
  searchText: string
) {
  try {
    let getBuyReqAPIPath = ''
    if (status !== undefined && status !== '') {
      getBuyReqAPIPath = `${GET_BUY_REQUEST_URL}?skip=${startIndex}&limit=${limitNumber}&status=${status}`
    } else {
      getBuyReqAPIPath = `${GET_BUY_REQUEST_URL}?skip=${startIndex}&limit=${limitNumber}&status=pending`
    }
    let buyRequestData: ListType = await axios.post(getBuyReqAPIPath, {
      searchText,
    })

    return buyRequestData
  } catch (e) {
    throw e
  }
}

// Create BuyRequest Data
export async function createBuyRequestData(buyRequestObject: any) {
  try {
    let createBuyRequestData = await axios.post(`${CREATE_BUY_REQUEST_URL}`, {
      ...buyRequestObject,
    })
    return createBuyRequestData
  } catch (e) {
    throw e
  }
}

export async function createBuyRequestDataWithValues(buyRequestObject: any, formValues: any) {
  try {
    let shipping_address: any = {}
    let billing_address: any = {}

    if (formValues) {
      billing_address.address_line_1 = formValues.address_line_1 ? formValues.address_line_1 : ''
      billing_address.address_line_2 = formValues.address_line_2 ? formValues.address_line_2 : ''
      billing_address.city = formValues.city ? formValues.city : ''
      billing_address.state = formValues.state ? formValues.state : ''
      billing_address.country = formValues.country ? formValues.country : ''
      billing_address.postal_code = formValues.postal_code ? formValues.postal_code : ''

      shipping_address.address_line_1 = formValues.shipment_address_line_1
        ? formValues.shipment_address_line_1
        : ''
      shipping_address.address_line_2 = formValues.shipment_address_line_2
        ? formValues.shipment_address_line_2
        : ''
      shipping_address.city = formValues.shipment_city ? formValues.shipment_city : ''
      shipping_address.state = formValues.shipment_state ? formValues.shipment_state : ''
      shipping_address.country = formValues.shipment_country ? formValues.shipment_country : ''
      shipping_address.postal_code = formValues.shipment_postal_code
        ? formValues.shipment_postal_code
        : ''
    }
    console.log(shipping_address, billing_address, 'address')
    let createBuyRequestData: authType = await axios.post(`${CREATE_BUY_REQUEST_URL}`, {
      ...buyRequestObject,
      shipping_address,
      billing_address,
    })
    return {...createBuyRequestData.data, role: 'client'}
  } catch (e) {
    throw e
  }
}

// Delete BuyRequest Data
export async function deleteBuyRequestData(buyRequestId: string) {
  try {
    let deleteBuyRequestData = await axios.delete(`${DELETE_BUY_REQUEST_URL}${buyRequestId}`)
    return deleteBuyRequestData
  } catch (e) {
    throw e
  }
}

// Change Status BuyRequest Data
export async function changeStatusBuyRequestData(buyRequestId: string, flag: boolean) {
  try {
    let buyRequestData = await axios.put(`${CHANGE_STATUS_BUY_REQUEST_URL}`, {
      buyRequestId,
      flag,
    })
    return buyRequestData
  } catch (e) {
    throw e
  }
}

// Edit BuyRequest Data
export async function editBuyRequestData(buyRequestStocks: any, buyRequestId: string,editStatus: string) {
  try {
    let editBuyRequestData = await axios.put(`${UPDATE_BUY_REQUEST_URL}`, {
      id: buyRequestId,
      status: editStatus,
      stock_ids : buyRequestStocks
    })
    return editBuyRequestData
  } catch (e) {
    throw e
  }
}

export async function getBuyRequestStockDetails(req_id: string) {
  try {
    let buyRequestData: ListType = await axios.get(`${GET_BUY_REQUEST_STOCK_DETAILS}?id=${req_id}`)
    return buyRequestData
  } catch (e) {
    throw e
  }
}
