/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {VendorsPage} from '../../component/vendors/VendorsPage'
import {shallowEqual, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'
import {Toaster} from '../../modules/comman/components/ToasterNoti'

const VendorsWrapper: FC = () => {
  const history = useHistory()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  /**
   * useEffect check the user role and based on allow to access the current module or not
   * else redirect to the defined router
   */
  useEffect(() => {
    // if (user?.role !== 'admin' && user?.role !== 'client') {
    //   history.push('/dashboard')
    //   Toaster({
    //     type: 'error',
    //     title: 'Authorized',
    //     description: 'You are not authorized to access the location',
    //   })
    // }
  }, [])

  return (
    <div>
      <VendorsPage />
    </div>
  )
}

export {VendorsWrapper}
