import React from 'react'
import axios from 'axios'
import {UserModel} from '../../../modules/auth/models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_BUY_REQUEST_URL = `${API_URL}api/v1/auth/vendor-buy-request`
export const UPDATE_STOCK_ACTION_REQUEST_URL = `${API_URL}api/v1/auth/vendor-stock-approval`

interface authType {
  data: UserModel
}

interface ListType {
  count?: any
  data: any
}
// Get BuyRequest Data
export async function getVendorRequestData(startIndex: number, limitNumber: number, status?: string) {
  try {
    let getBuyReqAPIPath = ''
    if (status !== undefined && status !== '') {
      getBuyReqAPIPath = `${GET_BUY_REQUEST_URL}?skip=${startIndex}&limit=${limitNumber}&status=${status}`
    } else {
      getBuyReqAPIPath = `${GET_BUY_REQUEST_URL}?skip=${startIndex}&limit=${limitNumber}&status=pending`
    }
    let buyRequestData: ListType = await axios.get(getBuyReqAPIPath)
    // let buyRequestData: ListType = await axios.get(`${GET_BUY_REQUEST_URL}`)
    return buyRequestData
  } catch (e) {
    throw e
  }
}

// Get BuyRequest Data
export async function getVendorSearchRequestData(startIndex: number, limitNumber: number,searchText: string, status?: string) {
  try {
    let getBuyReqAPIPath = ''
    if (status !== undefined && status !== '') {
      getBuyReqAPIPath = `${GET_BUY_REQUEST_URL}?skip=${startIndex}&limit=${limitNumber}&searchText=${searchText}&status=${status}`
    } else {
      getBuyReqAPIPath = `${GET_BUY_REQUEST_URL}?skip=${startIndex}&limit=${limitNumber}&searchText=${searchText}&status=pending`
    }
    let buyRequestData: ListType = await axios.get(getBuyReqAPIPath)
    // let buyRequestData: ListType = await axios.get(`${GET_BUY_REQUEST_URL}`)
    return buyRequestData
  } catch (e) {
    throw e
  }
}

export async function vendorApprovalRequestApi(vendorApprovalObj: any) {
  try {
    let approvedData = await axios.post(`${UPDATE_STOCK_ACTION_REQUEST_URL}`, {
      ...vendorApprovalObj
    })
    return approvedData
  } catch (e) {
    throw e
  }
}
