/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getSalesReportData} from '../redux/SalesReportCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as sales from '../redux/SalesReportRedux'
import ListSalesReportData from './ListSalesReportData'
import {RootState} from '../../../../setup'
import moment from 'moment'
import {DateRangePicker} from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

const format = 'YYYY-MM-DD'

const formatDate = (date: Date) => date.toISOString().split('T')[0];

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const salesReportStartDate: any = useSelector<RootState>(
    ({sales}) => sales.startDate,
    shallowEqual
  )
  const salesReportEndDate: any = useSelector<RootState>(({sales}) => sales.endDate, shallowEqual)

  const [dates, setDates] = useState<{startDate: any; endDate: any}>({
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
  })
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);

  const handleDatesChange = ({ startDate, endDate }: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
    setDates({ startDate, endDate });
    if (startDate && endDate) {
      const start_date = formatDate(startDate.toDate());
      const end_date = formatDate(endDate.toDate());
      callSalesReportData(start_date, end_date);
    }
  };

  // State for the date range picker
  const [selectedDateRange, setSelectedDateRange] = useState<[Date | null, Date | null]>([
    moment(salesReportStartDate, format).toDate(),
    moment(salesReportEndDate, format).toDate(),
  ])

  const callSalesReportData = async (start_date: any, end_date: any) => {
    try {
      console.log(start_date, end_date, 'datesss')
      let salesData: any = await getSalesReportData(0, 10, start_date, end_date)

      if (salesData.data) {
        await dispatch(sales.actions.setSalesReportLoading(true))
        // once sales data set then loading will automatically off using saga middleware
        await dispatch(sales.actions.setSalesReportData(salesData.data))
        await dispatch(sales.actions.setTotalSalesData(salesData.total_selling))
        await dispatch(sales.actions.setSalesReportDataTotal(salesData.count))
        await dispatch(sales.actions.setStartDate(start_date))
        await dispatch(sales.actions.setEndDate(end_date))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    const todaysDate = new Date();
    const startOfMonth = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1);
    const endOfMonth = new Date(todaysDate.getFullYear(), todaysDate.getMonth() + 1, 0);
    const start_date = formatDate(startOfMonth);
    const end_date = formatDate(endOfMonth);
    callSalesReportData(start_date, end_date);
  }, [])

  return (
    <div>
      <div className='mb-4'>
      <DateRangePicker
          startDate={dates.startDate}
          endDate={dates.endDate}
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={(input: any) => setFocusedInput(input)}
          isOutsideRange={() => false}
          startDateId="start_date_id"
          endDateId="end_date_id"
          
        />
      </div>
      <ListSalesReportData />
    </div>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/sales-report/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/sales-report/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.SALESREPORT'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
