/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ListWrapper} from './containers/List'
import BuyRequestPreviewPage from './containers/BuyRequestPreviewPage'

export function BuyRequestPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/buy-request/list' exact={true} component={ListWrapper} />
        <Route path='/buy-request/preview/:id' exact={true} component={BuyRequestPreviewPage} />
        <Redirect from='/buy-request' exact={true} to='/buy-request/list' />
        <Redirect to='/buy-request/list' />
      </Switch>
    </div>
  )
}
