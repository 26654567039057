import React, {FC, useEffect, useState} from 'react'

interface ViewUsersDetailsProps {
  data: any
  handleCancel: () => void
}

const ViewUsersDetails: FC<ViewUsersDetailsProps> = (props: any) => {
  useEffect(() => {}, [])

  const {
    email,
    phone,
    business_entity_name,
    type_of_business,
    years_at_present_location,
    city,
    country,
  } = props?.data

  return (
    <>
      {props.data ? (
        <div>
          <div style={{marginBottom: '30px'}}>
            <div className='col-lg-6 col-md-12 col-sm-12' style={{padding: 0}}>
              <div style={{fontSize: '20px', fontWeight: 500}}>Client Details : </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-12'>
              <div className='row' style={{marginBottom: '20px'}}>
                <div
                  className='col-sm-6 col-12'
                  style={{
                    fontWeight: 600,
                    letterSpacing: '0.5px',
                    color: '#000',
                  }}
                >
                  Name :
                </div>
                <div className='col-sm-6 col-12' style={{}}>
                  {props.data.first_name && props.data.last_name
                    ? `${props.data.first_name} ${props.data.last_name}`
                    : props.data.first_name
                    ? props.data.first_name
                    : '-'}
                </div>
              </div>
              <div className='row' style={{marginBottom: '20px'}}>
                <div
                  className='col-sm-6 col-12'
                  style={{
                    fontWeight: 600,
                    letterSpacing: '0.5px',
                    color: '#000',
                  }}
                >
                  Email
                </div>
                <div className='col-sm-6 col-12' style={{}}>
                  {' '}
                  {email ? email : ''}
                </div>
              </div>
              <div className='row' style={{marginBottom: '20px'}}>
                <div
                  className='col-sm-6 col-12'
                  style={{
                    fontWeight: 600,
                    letterSpacing: '0.5px',
                    color: '#000',
                  }}
                >
                  Phone
                </div>
                <div className='col-sm-6 col-12' style={{}}>
                  {' '}
                  {phone ? phone : ''}
                </div>
              </div>
              <div className='row' style={{marginBottom: '20px'}}>
                <div
                  className='col-sm-6 col-12'
                  style={{
                    fontWeight: 600,
                    letterSpacing: '0.5px',
                    color: '#000',
                  }}
                >
                  Business Entity Name
                </div>
                <div className='col-sm-6 col-12' style={{}}>
                  {' '}
                  {business_entity_name ? business_entity_name : ''}
                </div>
              </div>
            </div>
            <div className='col-md-6 col-12'>
              <div className='row' style={{marginBottom: '20px'}}>
                <div
                  className='col-sm-6 col-12'
                  style={{
                    fontWeight: 600,
                    letterSpacing: '0.5px',
                    color: '#000',
                  }}
                >
                  Type Of Business
                </div>
                <div className='col-sm-6 col-12' style={{}}>
                  {' '}
                  {type_of_business ? type_of_business : ''}
                </div>
              </div>
              <div className='row' style={{marginBottom: '20px'}}>
                <div
                  className='col-sm-6 col-12'
                  style={{
                    fontWeight: 600,
                    letterSpacing: '0.5px',
                    color: '#000',
                  }}
                >
                  Years At Present Location
                </div>
                <div className='col-sm-6 col-12' style={{}}>
                  {' '}
                  {years_at_present_location ? years_at_present_location : '-'}
                </div>
              </div>
              <div className='row' style={{marginBottom: '20px'}}>
                <div
                  className='col-sm-6 col-12'
                  style={{
                    fontWeight: 600,
                    letterSpacing: '0.5px',
                    color: '#000',
                  }}
                >
                  City
                </div>
                <div className='col-sm-6 col-12' style={{}}>
                  {' '}
                  {city ? city : ''}
                </div>
              </div>
              <div className='row' style={{marginBottom: '20px'}}>
                <div
                  className='col-sm-6 col-12'
                  style={{
                    fontWeight: 600,
                    letterSpacing: '0.5px',
                    color: '#000',
                  }}
                >
                  Country
                </div>
                <div className='col-sm-6 col-12' style={{}}>
                  {' '}
                  {country ? country : ''}
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          <div style={{marginBottom: '30px'}}>
            <div className='col-lg-6 col-md-12 col-sm-12' style={{padding: 0}}>
              <div style={{fontSize: '20px', fontWeight: 500}}>References Details : </div>
            </div>
          </div>
          {props.data.trade_references ? (
            <>
              {console.log(props.data.trade_references, 'props.data.trade_references')}
              {props.data.trade_references.map((item: any, index: number) => (
                <>
                  <div key={index}>
                    <div className='row' key={index}>
                      <div className='col-md-6 col-12'>
                        <div className='row' style={{marginBottom: '20px'}}>
                          <div
                            className='col-sm-6 col-12'
                            style={{
                              fontWeight: 600,
                              letterSpacing: '0.5px',
                              color: '#000',
                            }}
                          >
                            Company Name :
                          </div>
                          <div className='col-sm-6 col-12' style={{}}>
                            {item.company_name ? item.company_name : '-'}
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6 col-12'>
                        <div className='row' style={{marginBottom: '20px'}}>
                          <div
                            className='col-sm-6 col-12'
                            style={{
                              fontWeight: 600,
                              letterSpacing: '0.5px',
                              color: '#000',
                            }}
                          >
                            Contact Person Name
                          </div>
                          <div className='col-sm-6 col-12' style={{}}>
                            {' '}
                            {item.contact_person_name ? item.contact_person_name : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row' key={index}>
                      <div className='col-md-6 col-12'>
                        <div className='row' style={{marginBottom: '20px'}}>
                          <div
                            className='col-sm-6 col-12'
                            style={{
                              fontWeight: 600,
                              letterSpacing: '0.5px',
                              color: '#000',
                            }}
                          >
                            Email :
                          </div>
                          <div className='col-sm-6 col-12' style={{}}>
                            {item.email ? item.email : '-'}
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6 col-12'>
                        <div className='row' style={{marginBottom: '20px'}}>
                          <div
                            className='col-sm-6 col-12'
                            style={{
                              fontWeight: 600,
                              letterSpacing: '0.5px',
                              color: '#000',
                            }}
                          >
                            Phone
                          </div>
                          <div className='col-sm-6 col-12' style={{}}>
                            {' '}
                            {item.phone ? item.phone : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                </>
              ))}
            </>
          ) : (
            <div>No References Found</div>
          )}
          <div style={{marginBottom: '30px'}}>
            <div className='col-lg-6 col-md-12 col-sm-12' style={{padding: 0}}>
              <div style={{fontSize: '20px', fontWeight: 500}}>Billing Address : </div>
            </div>
          </div>
          {!props.data?.is_address_same ? (
            <div className='row'>
              <div className='col-md-6 col-12'>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    Street :
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {props.data?.billing_address?.street
                      ? props.data?.billing_address?.street
                      : '-'}
                  </div>
                </div>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    City
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {' '}
                    {props.data?.billing_address?.city ? props.data?.billing_address?.city : '-'}
                  </div>
                </div>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    State
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {' '}
                    {props.data?.billing_address?.state ? props.data?.billing_address?.state : '-'}
                  </div>
                </div>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    Country
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {' '}
                    {props.data?.billing_address?.country
                      ? props.data?.billing_address?.country
                      : '-'}
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-12'>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    Zip Code
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {' '}
                    {props.data?.billing_address?.zip_code
                      ? props.data?.billing_address?.zip_code
                      : '-'}
                  </div>
                </div>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    Years At Present Location
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {' '}
                    {years_at_present_location ? years_at_present_location : '-'}
                  </div>
                </div>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    City
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {' '}
                    {city ? city : ''}
                  </div>
                </div>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    Country
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {' '}
                    {country ? country : ''}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='row'>
              <div className='col-md-6 col-12'>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    Street :
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {props.data?.shipping_address?.street
                      ? props.data?.shipping_address?.street
                      : '-'}
                  </div>
                </div>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    City
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {' '}
                    {props.data?.shipping_address?.city ? props.data?.shipping_address?.city : '-'}
                  </div>
                </div>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    State
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {' '}
                    {props.data?.shipping_address?.state
                      ? props.data?.shipping_address?.state
                      : '-'}
                  </div>
                </div>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    Country
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {' '}
                    {props.data?.shipping_address?.country
                      ? props.data?.shipping_address?.country
                      : '-'}
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-12'>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    Zip Code
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {' '}
                    {props.data?.shipping_address?.zip_code
                      ? props.data?.shipping_address?.zip_code
                      : '-'}
                  </div>
                </div>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    Years At Present Location
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {' '}
                    {years_at_present_location ? years_at_present_location : '-'}
                  </div>
                </div>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    City
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {' '}
                    {city ? city : ''}
                  </div>
                </div>
                <div className='row' style={{marginBottom: '20px'}}>
                  <div
                    className='col-sm-6 col-12'
                    style={{
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      color: '#000',
                    }}
                  >
                    Country
                  </div>
                  <div className='col-sm-6 col-12' style={{}}>
                    {' '}
                    {country ? country : ''}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <h1>NO DATA FOUND</h1>
        </>
      )}
    </>
  )
}

export default ViewUsersDetails
