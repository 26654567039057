import React, {useState,useEffect} from 'react'
import {CommanModal} from '../../modules/comman/components/ModalComponent'
import {InputButtonComponent} from '../../modules/comman/formComponents/InputButtonComponent'
import VendorDetailsForm from './VendorDetailsForm'
import {RootState} from '../../../setup'
import {shallowEqual, useSelector} from 'react-redux'

export const VendorTermsForm = () => {
  const isAuthorized: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  
  const [showForm, setShowForm] = useState(false)
  const [showModal, setShowModal] = useState(true)
  {console.log("inside terms",isAuthorized)}

  return (
    <CommanModal
      show={showModal}
      handleClose={() => {}}
      title={showForm ? 'Fill Up Your Details' :'Terms And Conditons'}
      submitText={''}
      isScrollable={true}
    >
      {showForm ? (
        <VendorDetailsForm handleCancel={() => {setShowModal(false)}} termsPage={() => {setShowForm(false)}} />
      ) : (
        <>
          <div style={{fontWeight: 500}}>
            Welcome to Diamond Company. These Terms and Conditions ("Terms") govern your use of our
            website and services as a Vendor. By registering as a Vendor and using our platform, you
            agree to comply with these Terms. Please read them carefully.
            <br />
            <br />
            Vendors must provide accurate and complete information during the registration process.
            Vendors are responsible for maintaining the confidentiality of their account
            credentials. Vendors must notify the Company immediately of any unauthorized use of
            their account.
            <br />
            <br />
            Vendors can list diamonds for sale on the platform. Vendors must provide accurate
            descriptions, certifications, and prices for each listed diamond. Vendors are
            responsible for ensuring the authenticity and quality of the diamonds listed.
            <br />
            <br />
            The Company will facilitate payment processing between Clients and Vendors. The Company
            may charge a commission or service fee for each transaction, as specified in the Vendor
            Agreement. Payments will be disbursed to Vendors after deducting applicable fees and
            commissions.
            <br />
            <br />
            Vendors must cooperate with the Company in the KYC process for Clients. Vendors are
            required to provide any necessary documentation or information to facilitate KYC
            verification. Vendors must ensure that all transactions comply with anti-money
            laundering (AML) and counter-terrorism financing (CTF) regulations.
            <br />
            <br />
            Vendors retain ownership of their listed diamond images and descriptions. Vendors grant
            the Company a non-exclusive, royalty-free license to use, display, and promote their
            listed diamonds on the platform.
            <br />
            <br />
            Vendors must maintain the confidentiality of any sensitive information obtained through
            the platform. Vendors are prohibited from sharing client information with third parties
            without explicit consent from the Company and the Client.
            <br />
            <br />
            The Company is not liable for any direct, indirect, incidental, or consequential damages
            arising from the use of the platform by Vendors. The Company does not guarantee the sale
            of diamonds listed by Vendors on the platform.
            <br />
            <br />
            These Terms shall be governed by and construed in accordance with the laws of
            [Jurisdiction]. Any disputes arising out of or in connection with these Terms shall be
            subject to the exclusive jurisdiction of the courts of [Jurisdiction].
            <br />
            <br />
            The Company reserves the right to amend these Terms at any time. Vendors will be
            notified of any changes to these Terms via email or through the platform. Continued use
            of the platform after any amendments constitutes acceptance of the new Terms.
            <br />
            <br />
          </div>
          <div className='card-footer'>
            <div className='d-flex justify-content-end'>
              <InputButtonComponent onClick={() => {setShowForm(true)}}>Next</InputButtonComponent>
            </div>
          </div>
        </>
      )}
    </CommanModal>
  )
}
