/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createVendorData, getVendorsData} from '../redux/VendorsCRUD'
import * as vendors from '../redux/VendorsRedux'
import {CreateVendorForm} from './CreateVendorForm'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  // call initial data for Form
  const callInitialData = async () => {
    try {
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callClientData = async () => {
    try {
      let clientData = await getVendorsData(0, 10)
      if (clientData.data) {
        await dispatch(vendors.actions.setVendorsLoading(true))
        // once vendors data set then loading will automatically off using saga middleware
        await dispatch(vendors.actions.setVendorsData(clientData.data))
        await dispatch(vendors.actions.setVendorsDataTotal(clientData.count))
        history.push('/vendors/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: any) => {
    try {
      let createUser = await createVendorData(values)
      
      if(createUser && createUser.status == 200) {
        Toaster({
          type: 'success',
          title: 'VENDOR',
          description: 'Added SuccessFully',
        }) 
      }
      else {
        Toaster({
          type: 'error',
          title: 'VENDOR',
          description: 'Failed TO Create Vendor',
        })  
      }
      await callClientData()
    } catch (e) {
      console.log('!!!!Error', e)
      Toaster({
        type: 'error',
        title: 'VENDOR',
        description: 'Failed To Add User',
      })
    }
  }

  return (
    <div>
      <CreateVendorForm onSubmit={(values: any) => addSubmit(values)} />
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'create',
            path: '/vendors/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'create',
            path: '/vendors/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.VENDOR'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
