/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as chats from '../redux/ChatsRedux'
import {RootState} from '../../../../setup'
import ChatMessages from './ChatMessages'
import {io} from 'socket.io-client'
import {getChatUserListData} from '../redux/ChatsCRUD'
import {config} from '../../../comman/constants'
import { Spin } from 'antd';

// const baseSocketUrl: string = 'http://192.168.29.28:3002/'
const baseSocketUrl: string = config.chatConfigUrl || 'http://localhost:2807/'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const socketInstance: any = io(`${baseSocketUrl}`)

  const onConnect = () => {
    console.log('socket connected')
  }

  const onDisconnect = () => {
    console.log('socket disconnect')
  }

  useEffect(() => {
    callChatListData(true)
    // setInterval to call the api after 1 minute
    const intervalId = setInterval(callChatListData, 10000)

    socketInstance.on('connect', onConnect)
    socketInstance.on('disconnect', onDisconnect)
    return () => {
      clearInterval(intervalId)
      socketInstance.off('connect', onConnect)
      socketInstance.off('disconnect', onDisconnect)
    }
  }, [])

  const callChatListData = async  (initialLoad = false)  => {
    try {
        if (initialLoad) {
        setLoader(true)
      }
      let chatUsersList = await getChatUserListData()

      if (chatUsersList && chatUsersList.data) {
        await dispatch(chats.actions.setChatsLoading(true))
        // once chats data set then loading will automatically off using saga middleware
        await dispatch(chats.actions.setChatsData(chatUsersList.data))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
    finally {
      if (isFirstLoad) {
        setLoader(false)
        setIsFirstLoad(false)
      }
    }
  }

  return (
    <div>
      <Spin spinning={loader} tip="Loading...">
      <ChatMessages socket={socketInstance} />
      </Spin>
    </div>
  )
}

const ChatWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/chats/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/chats/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.CHATS'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ChatWraaper}
