export function returnStockString(singleStockObject: any) {
    let stockString = ''
    if (singleStockObject && singleStockObject.shape) {
      stockString += singleStockObject.shape + ' | '
    }
    if (singleStockObject && singleStockObject.weight) {
      stockString += singleStockObject.weight + ' | '
    }
    if (singleStockObject && singleStockObject.color) {
      stockString += singleStockObject.color + ' | '
    }
    if (singleStockObject && singleStockObject.clarity) {
      stockString += singleStockObject.clarity + ' | '
    }
    let cutPolishSym = ''
    if (singleStockObject && singleStockObject.cut) {
      cutPolishSym += singleStockObject.cut + ' - '
    }
    if (singleStockObject && singleStockObject.polish) {
      cutPolishSym += singleStockObject.polish + ' - '
    }
    if (singleStockObject && singleStockObject.symmetry) {
      cutPolishSym += singleStockObject.symmetry
    }
    if (cutPolishSym != '') {
      stockString += cutPolishSym.replace(/^\-|\-\s$/g, '') + ' | '
    }
    if (singleStockObject && singleStockObject.fluorescence_intensity) {
      stockString += singleStockObject.fluorescence_intensity
    }
    stockString = stockString.replace(/^\||\|\s$/g, '')
    return stockString.toUpperCase()
  }
  
  export function returnStockId(stockId: string) {
    return stockId.toUpperCase()
  }
  
  export function returnPriceInThousandFormat(price: number, digit: number = 2) {
    // return (Math.round(price * 100) / 100).toFixed(digit).toLocaleString()
    return price.toLocaleString(undefined, {
      maximumFractionDigits: digit,
      minimumFractionDigits: digit,
    })
  }
  
  export function returnPriceInThousandFormatWithCurrencySign(price: number, digit: number = 2) {
    // return (Math.round(price * 100) / 100).toFixed(digit).toLocaleString()
    return "$" + price.toLocaleString(undefined, {
      maximumFractionDigits: digit,
      minimumFractionDigits: digit,
    })
  }
  