import React from 'react'
import axios from 'axios'
// import {InventoryModal} from '../models/InventoryModal'
//import {IInventoryFormValues} from '../containers/CreateInventoryForm'
import {IImportInventoryFormValues} from '../containers/ImportInventoryForm'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_INVENTORY_URL = `${API_URL}api/v1/auth/admin/stock-list/`
export const GET_TWIN_INVENTORY_URL = `${API_URL}v1/auth/twin-stock/`
export const GET_MATRIX_INVENTORY_URL = `${API_URL}v1/auth/matrix-stock/`
export const GET_SINGLE_INVENTORY_URL = `${API_URL}v1/auth/stock/`
export const SEARCH_INVENTORY_URL = `${API_URL}v1/auth/filter-stock/`
export const CREATE_INVENTORY_URL = `${API_URL}api/v1/auth/admin/stock/`
export const EDIT_INVENTORY_URL = `${API_URL}v1/auth/inventory/`
export const DELETE_INVENTORY_URL = `${API_URL}v1/auth/inventory/`
export const CHANGE_STATUS_INVENTORY_URL = `${API_URL}v1/auth/inventory/active-status/`
export const EXPORT_ALL_STOCK = `${API_URL}v1/auth/all/stock/`
export const EXPORT_SELECTED_STOCK = `${API_URL}v1/auth/all/select/stock/`
export const EDIT_STOCK_FEATURE_STATUS_URL = `${API_URL}api/v1/auth/admin/stock/status/`
export const EDIT_STOCK_ARRIVAL_STATUS_URL = `${API_URL}api/v1/auth/admin/stock/status/`
export const GET_PARTICULAR_INVENTORY_DETAIL_URL = `${API_URL}api/v1/auth/admin/stock-details`

interface ListType {
  count?: any
  data: any
}

// Get Inventory Data
export async function getInventoryData(startIndex: number, limitNumber: number,is_admin: boolean) {
  try {
    console.log(is_admin,"is_admin")
    let inventoryData: ListType = await axios.post(`${GET_INVENTORY_URL}?skip=${startIndex}&limit=${limitNumber}&is_admin=${is_admin}`)
    return inventoryData
  } catch (e) {
    throw e
  }
}

// Get Twin Inventory Data
export async function getTwinInventoryData(startIndex: number, limitNumber: number) {
  try {
    let twinInventoryData = await axios.get(`${GET_TWIN_INVENTORY_URL}${startIndex}/${limitNumber}`)
    return twinInventoryData
  } catch (e) {
    throw e
  }
}

// Get Matrix Inventory Data
export async function getMatrixInventoryData(filterObject: any) {
  try {
    let matrixInventoryData = await axios.post(`${GET_MATRIX_INVENTORY_URL}`, {
      filterObject,
    })
    return matrixInventoryData
  } catch (e) {
    throw e
  }
}

// Get Single Inventory Data
export async function getSingleInventoryData(Id: number) {
  try {
    let singleInventoryData = await axios.get(`${GET_PARTICULAR_INVENTORY_DETAIL_URL}?id=${Id}`)
    return singleInventoryData
  } catch (e) {
    throw e
  }
}

// Search Inventory Data
export async function getSearchInventoryData(
  startIndex: number,
  limitNumber: number,
  filterObject: any,
  is_admin: boolean,
  searchText: any,
) {
  try {
    const updatedFilterObject = {
      ...filterObject,
      certificationId: searchText,
    };
    let inventoryData: ListType = await axios.post(`${GET_INVENTORY_URL}?skip=${startIndex}&limit=${limitNumber}&is_admin=${is_admin}`, {
      filterObject: updatedFilterObject,     
    })
    return inventoryData
  } catch (e) {
    throw e
  }
}

// Create Inventory Data
// export async function createInventoryData(inventoryObject: IInventoryFormValues) {
//   try {
//     let createInventoryData = await axios.post(`${CREATE_INVENTORY_URL}`, {
//       ...inventoryObject,
//     })
//     return createInventoryData
//   } catch (e) {
//     throw e
//   }
// }

// Create Inventory Data
export async function importInventoryData(inventoryObject: IImportInventoryFormValues) {
  try {
    if(inventoryObject && inventoryObject.replace_all == "" || inventoryObject.replace_all == null) {
      inventoryObject.replace_all = false
    }
    let createInventoryData = await axios.post(`${CREATE_INVENTORY_URL}`, {
      ...inventoryObject,
    })
    return createInventoryData
  } catch (e) {
    throw e
  }
}

// Delete Inventory Data
export async function deleteInventoryData(inventoryId: string) {
  try {
    let deleteInventoryData = await axios.delete(`${DELETE_INVENTORY_URL}${inventoryId}`)
    return deleteInventoryData
  } catch (e) {
    throw e
  }
}

// Change Status Inventory Data
export async function changeStatusInventoryData(inventoryId: string, flag: boolean) {
  try {
    let inventoryData = await axios.put(`${CHANGE_STATUS_INVENTORY_URL}`, {
      inventoryId,
      flag,
    })
    return inventoryData
  } catch (e) {
    throw e
  }
}

// Edit Inventory Data
// export async function editInventoryData(
//   inventoryObject: IInventoryFormValues,
//   inventoryId: string
// ) {
//   try {
//     let editInventoryData = await axios.put(`${EDIT_INVENTORY_URL}`, {
//       ...inventoryObject,
//       inventoryId,
//     })
//     return editInventoryData
//   } catch (e) {
//     throw e
//   }
// }

// export selected stock list
export async function exportSelectedStockList(stockIds: any) {
  try {
    let exportSelectedStockData = await axios.post(`${EXPORT_SELECTED_STOCK}`, {
      stockIds,
    })
    return exportSelectedStockData
  } catch (e) {
    throw e
  }
}

// export All Stock List
export async function exportAllStockList() {
  try {
    let exportAllStockData = await axios.get(`${EXPORT_ALL_STOCK}`)
    return exportAllStockData
  } catch (e) {
    throw e
  }
}

// Edit Stock Feature Data
export async function editStockFeatureStatusData(stockId: string, flag: any) {
  try {
    let editstockData = await axios.put(`${EDIT_STOCK_FEATURE_STATUS_URL}`, {
      id: stockId,
      is_featured: flag,
    })
    return editstockData
  } catch (e) {
    throw e
  }
}
// Edit Stock arrival Data
export async function editStockArrivalStatusData(stockId: string, flag: any) {
  try {
    let editstockData = await axios.put(`${EDIT_STOCK_ARRIVAL_STATUS_URL}`, {
      id: stockId,
      is_new_arrival: flag,
    })
    return editstockData
  } catch (e) {
    throw e
  }
}

export async function getSearchSuggestInventoryData(
  startIndex: number,
  limitNumber: number,
  filterObject: any,
) {
  try {
    let inventoryData: ListType = await axios.post(`${GET_INVENTORY_URL}?skip=${startIndex}&limit=${limitNumber}`, {
      filterObject,
    })
    return inventoryData
  } catch (e) {
    throw e
  }
}