/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getUsersData} from '../redux/KycCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as kyc from '../redux/KycRedux'
import ListKycUserData from './ListKycUserData'
import {Tabs} from 'antd'
import {RootState} from '../../../../setup'
import {Spin} from 'antd'

const {TabPane} = Tabs

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const [tabKey, setTabKey] = useState('1')
  const [isLoading, setIsLoading] = useState(false)

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  const callOrderData = async (status: boolean) => {
    try {
      setIsLoading(true)

      let kycData = await getUsersData(0, 10, status)

      if (kycData.data) {
        await dispatch(kyc.actions.setKycLoading(true))
        // once kyc data set then loading will automatically off using saga middleware
        await dispatch(kyc.actions.setKycData(kycData.data))
        await dispatch(kyc.actions.setKycDataTotal(kycData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeTab = (key: string) => {
    console.log(key, typeof key, 'key')
    setTabKey(key)
    // if (key === '3') {
    //   callOrderData('CANCELED')
    // } else
     if (key === '2') {
      callOrderData(true)
    } else {
      callOrderData(false)
    }
  }

  useEffect(() => {
    callOrderData(false)
  }, [])

  return (
    <Spin spinning={isLoading} tip='Loading...'>
      <div className='card-container'>
        <Tabs defaultActiveKey='1' onChange={onChangeTab} type='card'>
          <TabPane tab='Non Verified Users' key='1'>
            <ListKycUserData tabkey='1' />
          </TabPane>
          {(user.role == 'super_admin' || user.role == 'sub_admin') && (
            <TabPane tab='Verified Users' key='2'>
              <ListKycUserData tabkey='2' />
            </TabPane>
          )}
          {/* {(user.role == 'super_admin' || user.role == 'sub_admin') && (
            <TabPane tab='Rejected' key='3'>
              <ListKycUserData tabkey='CANCELED' />
            </TabPane>
          )} */}
        </Tabs>
      </div>
    </Spin>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/kyc/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/kyc/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.KYC'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
