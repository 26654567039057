import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {changeReturnOrderStausDataApi} from '../redux/ReturnOrderCRUD'

interface IRenderFormikForm {
  values: IFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IFormValues {
  status: string
  received_amount: any
  return_order_id: any
}

interface IEditProps {
  data: any
  handleCancel?: any
}

export class ChangeReturnOrderStatusForm extends React.Component<IEditProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      dropDownOption: [],
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.data.status !== this.props.data.status) {
      this.setOrderStatusOptions(this.props.data.status)
    }
  }

  setOrderStatusOptions(status: any) {
    let OrderStatusOptions: any = []

    if (status == 'PENDING') {
      OrderStatusOptions = [
        {label: 'Pending', value: 'PENDING'},
        {label: 'Client Shipped', value: 'CLIENT-SHIPPED'},
      ]
    } else if (status == 'CLIENT-SHIPPED') {
      OrderStatusOptions = [
        {label: 'Client Shipped', value: 'CLIENT-SHIPPED'},
        {label: 'Vendor Shipped', value: 'VENDOR-SHIPPED'},
      ]
    } else if (status == 'VENDOR-SHIPPED') {
      OrderStatusOptions = [
        {label: 'Vendor Shipped', value: 'VENDOR-SHIPPED'},
        {label: 'Received', value: 'RECEIVED'},
      ]
    } else if (status == 'RECEIVED') {
      OrderStatusOptions = [{label: 'Received', value: 'RECEIVED'}]
    }

    this.setState({dropDownOption: OrderStatusOptions})
  }

  componentDidMount() {
    this.setOrderStatusOptions(this.props.data.status)
  }

  public validateForm = (values: IFormValues) => {
    const errors: any = {}
    if (values.status == 'RECEIVED' && !values.received_amount) {
      errors.received_amount = 'Received Amount is Required'
    }
    return errors
  }

  public handleSubmit = async (values: IFormValues, action: any) => {
    const {handleCancel} = this.props
    try {
      if (!values.received_amount || isNaN(values.received_amount)) {
        delete values.received_amount
      }

      let orderStatusChange = await changeReturnOrderStausDataApi(values)

      if (orderStatusChange && orderStatusChange.status == 200) {
        Toaster({
          type: 'success',
          title: 'Return Order',
          description: 'Status Updated Successfully',
        })
      }
    } catch (e) {
      console.log(e, 'error')
    } finally {
      handleCancel()
      this.setState({
        visible: false,
      })
    }
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    const {data} = this.props
    console.log(data)
    return (
      <div className='container'>
        <div className='card card-custom'>
          {data && (
            <div className='card-body'>
              <div>
                <Formik
                  initialValues={{
                    status: data.status ? data.status : '',
                    received_amount: data.received_amount ? data.received_amount : NaN,
                    return_order_id: data.id ? data.id : '',
                  }}
                  validate={this.validateForm}
                  onSubmit={this.handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,
                  }: IRenderFormikForm) => {
                    return (
                      <div className='table-form'>
                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputSingleSelect
                              input={{
                                id: 'status',
                                name: 'status',
                                value: values.status,
                                options: this.state.dropDownOption,
                              }}
                              placeholder='Select Status'
                              onChangeMethod={(value: string) => {
                                setFieldValue('status', value)
                              }}
                              label='Status'
                              clearable={false}
                              error={errors}
                              touched={touched}
                            />
                          </div>
                          {values.status == 'RECEIVED' && (
                            <div className='col-md-6 col-lg-6 col-sm-12'>
                              <InputNumberText
                                input={{
                                  value: values.received_amount,
                                  id: 'received_amount',
                                  name: 'received_amount',
                                }}
                                placeholder='Enter Received Amount'
                                onChange={(value: number) => {
                                  handleChange(value)
                                  setFieldValue('received_amount', value)
                                }}
                                onBlur={handleBlur}
                                label='Received Amount'
                                error={errors}
                                touched={touched}
                                required={true}
                              />
                            </div>
                          )}
                        </div>
                        {data && data.status !== 'RECEIVED' && (
                          <div className='custom-card-footer'>
                            <div className='float-right'>
                              <InputButtonComponent onClick={handleSubmit}>
                                Save
                              </InputButtonComponent>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  }}
                </Formik>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
