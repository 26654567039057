/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import { getVendorsData} from '../redux/VendorsCRUD'
import {useDispatch} from 'react-redux'
import * as vendors from '../redux/VendorsRedux'
import ListVendorsData from './ListVendorsData'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const [searchPagination, setSearchPagination] = useState({
    skip: 0,
    limit: 10,
  })

  const callVendorsData = async () => {
    try {
      let vendorsData = await getVendorsData(searchPagination.skip, searchPagination.limit)
      if (vendorsData.data) {
        await dispatch(vendors.actions.setVendorsLoading(true))
        // once vendors data set then loading will automatically off using saga middleware
        await dispatch(vendors.actions.setVendorsData(vendorsData.data))
        await dispatch(vendors.actions.setVendorsDataTotal(vendorsData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callVendorsData()
  }, [])

  return (
    <div>
      <ListVendorsData />
    </div>
  )
}

const ListWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/vendors/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/vendors/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.VENDOR'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWrapper}
