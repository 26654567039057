/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createSubAdminData, getSubAdminData} from '../redux/SubAdminCRUD'
import * as subadmin from '../redux/SubAdminRedux'
import {CreateSubAdminForm} from './CreateSubAdminForm'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  // call initial data for Form
  const callInitialData = async () => {
    try {
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callAdminData = async () => {
    try {
      let adminData = await getSubAdminData(0, 10)
      if (adminData.data) {
        await dispatch(subadmin.actions.setSubAdminLoading(true))
        // once subadmin data set then loading will automatically off using saga middleware
        await dispatch(subadmin.actions.setSubAdminData(adminData.data))
        await dispatch(subadmin.actions.setSubAdminDataTotal(adminData.count))
        history.push('/subadmin/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: any) => {
    try {
      let createSubAdmin = await createSubAdminData(values)
      
      if(createSubAdmin && createSubAdmin.status == 200) {
        Toaster({
          type: 'success',
          title: 'SUB ADMIN',
          description: 'Added SuccessFully',
        }) 
      }
      else {
        Toaster({
          type: 'error',
          title: 'SUB ADMIN',
          description: 'Failed TO Create Sub Admin',
        })  
      }
      await callAdminData()
    } catch (e) {
      console.log('!!!!Error', e)
      Toaster({
        type: 'error',
        title: 'SUB ADMIN',
        description: 'Failed To Add Sub Admin',
      })
    }
  }

  return (
    <div>
      <CreateSubAdminForm onSubmit={(values: any) => addSubmit(values)} />
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'create',
            path: '/subadmin/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'create',
            path: '/subadmin/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.SUBADMIN'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
