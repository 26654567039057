import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export const GET_SUBADMIN_URL = `${API_URL}api/v1/auth/admin/subAdmin`
export const GET_SEARCH_SUBADMIN_URL = `${API_URL}api/v1/auth/admin/subAdmin`
export const CHANGE_STATUS_SUBADMIN_URL = `${API_URL}api/v1/auth/admin/subAdmin/updateStatus`
export const CREATE_SUBADMIN_URL = `${API_URL}api/v1/auth/admin/subAdmin`
export const EDIT_SUBADMIN_URL = `${API_URL}api/v1/auth/admin/subAdmin`
export const DELETE_SUBADMIN_URL = `${API_URL}api/v1/auth/admin/subAdmin`

interface ListType {
  count?: any
  data: any
}

// Get USers Data
export async function getSubAdminData(startIndex: number, limitNumber: number) {
    try {
      let usersData: ListType = await axios.get(`${GET_SUBADMIN_URL}?skip=${startIndex}&limit=${limitNumber}`)
      return usersData
    } catch (e) {
      throw e
    }
  }

  export async function getSearchSubAdminData(startIndex: number, limitNumber: number,searchText: string) {
    try {
      let usersData: ListType  = await axios.get(`${GET_SUBADMIN_URL}?skip=${startIndex}&limit=${limitNumber}&q=${searchText}`)
      return usersData
    } catch (e) {
      throw e
    }
  }  

  // Change Status User Data
export async function changeStatusSubAdminData(id: string, flag: boolean) {
  try {
    let userData = await axios.put(`${CHANGE_STATUS_SUBADMIN_URL}`, {
      id,
      isActive: flag,
    })
    return userData
  } catch (e) {
    throw e
  }
}

// Create User Data
export async function createSubAdminData(userObject: any) {
  try {
    let createUserData = await axios.post(`${CREATE_SUBADMIN_URL}`, {
      ...userObject,
    })
    return createUserData
  } catch (e) {
    throw e
  }
}

// Edit User Data
export async function editSubAdminData(userObject: any) {
  try {
    let editUserData = await axios.put(`${EDIT_SUBADMIN_URL}`, {
      ...userObject,
    })
    return editUserData
  } catch (e) {
    throw e
  }
}

// Delete User Data
export async function deleteSubAdminData(id: string) {
  try {
    let deleteUserData = await axios.delete(`${DELETE_SUBADMIN_URL}?id=${id}`)
    return deleteUserData
  } catch (e) {
    throw e
  }
}