/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getReturnOrderData} from '../redux/ReturnOrderCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as returnorder from '../redux/ReturnOrderRedux'
import ListReturnOrderData from './ListReturnOrderData'
import {Tabs} from 'antd'
import {RootState} from '../../../../setup'
import {Spin} from 'antd'

const {TabPane} = Tabs

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const [tabKey, setTabKey] = useState('1')
  const [isLoading, setIsLoading] = useState(false)

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  const callReturnOrderData = async (status: string) => {
    try {
      setIsLoading(true)

      let orderData
      // if (user.role === 'admin') {
      //   orderData = await getAdminOrderData(0, 10)
      // } else {
      orderData = await getReturnOrderData(0, 10, status,user.role ? user.role : '')
      // }
      if (orderData.data) {
        await dispatch(returnorder.actions.setReturnOrderLoading(true))
        // once returnorder data set then loading will automatically off using saga middleware
        await dispatch(returnorder.actions.setReturnOrderData(orderData.data))
        await dispatch(returnorder.actions.setReturnOrderDataTotal(orderData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeTab = (key: string) => {
    console.log(key, typeof key, 'key')
    setTabKey(key)
    // if (key === '3') {
    //   callReturnOrderData('CANCELED')
    // } else
    if (key === '4') {
      callReturnOrderData('Vendor')
    } else if (key === '3') {
      callReturnOrderData('Rejected')
    } else if (key === '2') {
      callReturnOrderData('Accepted')
    } else {
      callReturnOrderData('Pending')
    }
  }

  useEffect(() => {
    if (user && user.role && user.role == 'vendor') {
      callReturnOrderData('Vendor')
    } else {
      callReturnOrderData('Pending')
    }
  }, [])

  return (
    <Spin spinning={isLoading} tip='Loading...'>
      <div className='card-container'>
        {(user.role == 'super_admin' || user.role == 'sub_admin') && (
          <Tabs defaultActiveKey='1' onChange={onChangeTab} type='card'>
            <TabPane tab='Pending' key='1'>
              <ListReturnOrderData tabkey='Pending' />
            </TabPane>
            <TabPane tab='Accepted' key='2'>
              <ListReturnOrderData tabkey='Accepted' />
            </TabPane>
            <TabPane tab='Rejected' key='3'>
              <ListReturnOrderData tabkey='Rejected' />
            </TabPane>
            <TabPane tab='Vendor Stones' key='4'>
              <ListReturnOrderData tabkey='Vendor' />
            </TabPane>
          </Tabs>
        )}
        {user.role == 'vendor' && <ListReturnOrderData tabkey='Vendor' />}
      </div>
    </Spin>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/returnorder/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/returnorder/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.RETURNORDER'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
