/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ChangePassword} from './ChangePassword'
import {Tabs} from 'antd'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import { ChangeEmail } from './ChangeEmail'
import { AdminBillingDetails } from './AdminBillingDetails'

const {TabPane} = Tabs

const DisplaySetting: FC = () => {
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const {role, permission} = user

  return (
    <div>
      <div style={{marginBottom: '30px'}}>
        <ChangeEmail />
        <ChangePassword />
        {role === 'super_admin' && <AdminBillingDetails />}
      </div>
    </div>
  )
}

export {DisplaySetting}
