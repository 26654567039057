/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {DashboardPage} from '../../component/dashboard'

const DashboardWrapper: FC = () => {
  return (
    <div>
      <DashboardPage />
    </div>
  )
}

export {DashboardWrapper}
