/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getVendorRequestData} from '../redux/VendorStockCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as vendorstock from '../redux/VendorStockRedux'
import ListVendorStockRequestData from './ListVendorStockRequestData'
import {Tabs} from 'antd'
import {RootState} from '../../../../setup'
import {Spin} from 'antd'

const {TabPane} = Tabs

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const [tabKey, setTabKey] = useState('1')
  const [isLoading, setIsLoading] = useState(false)

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  const {role, permission} = user

  const callBuyRequestData = async (status = '') => {
    try {
      console.log(status, 'call')
      setIsLoading(true)
      let buyRequestData = await getVendorRequestData(0, 10, status)
      if (buyRequestData.data) {
        await dispatch(vendorstock.actions.setVendorStockLoading(true))
        // once vendorstock data set then loading will automatically off using saga middleware
        await dispatch(vendorstock.actions.setVendorStockData(buyRequestData.data))
        await dispatch(vendorstock.actions.setVendorStockDataTotal(buyRequestData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeTab = (key: string) => {
    setTabKey(key)
    if (key === '4') {
      callBuyRequestData('UPDATED')
    } else if (key === '3') {
      callBuyRequestData('CANCELED')
    } else if (key === '2') {
      callBuyRequestData('ACCEPTED')
    } else {
      callBuyRequestData('PENDING')
    }
  }

  useEffect(() => {
    {
      callBuyRequestData('PENDING')
    }
  }, [])

  return (
    // <div>
    //   <ListVendorStockRequestData />
    // </div>
    <div className='card p-5'>
      <Spin spinning={isLoading} tip='Loading...'>
        <div className='card-container'>
          <Tabs defaultActiveKey='1' onChange={onChangeTab} type='card'>
            <TabPane tab='Ongoing' key='1'>
              <ListVendorStockRequestData tabkey='PENDING' />
            </TabPane>
            <TabPane tab='Completed' key='2'>
              <ListVendorStockRequestData tabkey='ACCEPTED' />
            </TabPane>
            <TabPane tab='Rejected' key='3'>
              <ListVendorStockRequestData tabkey='CANCELED' />
            </TabPane>
            <TabPane tab='Updated' key='4'>
              <ListVendorStockRequestData tabkey='UPDATED' />
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </div>
  )
}

const ListWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/vendorstock/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/vendorstock/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.STOCKREQUEST'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWrapper}
