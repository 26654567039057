import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {vendorApprovalRequestApi} from '../redux/VendorStockCRUD'

interface IRenderFormikForm {
  values: IVendorFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IVendorFormValues {
  reject_reason: string
}

interface IVendorProps {
  data: any
  handleCancel: any
}

export class VendorRejectReason extends React.Component<IVendorProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  public validateForm = (values: IVendorFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    if (!values.reject_reason) {
      errors.reject_reason = 'Reason is required'
    }

    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = async (values: IVendorFormValues, action: any) => {
    try {
      const {data} = this.props

      let reqObj: any = {}
      reqObj.stock_id = data.stock_id ? data.stock_id : ''
      reqObj.vendor_id = data.vendor_id ? data.vendor_id : ''
      reqObj.buy_request_id = data.buy_request_id ? data.buy_request_id : ''
      reqObj.is_available = false
      reqObj.reject_reason = values.reject_reason

      this.setState({
        isLoading: true,
      })
      let aprrovalData = await vendorApprovalRequestApi(reqObj)

      if (aprrovalData && aprrovalData.status == 200) {
        Toaster({
          type: 'success',
          title: 'STOCK REQUEST',
          description: 'Action Taken SuccessFully',
        })
      } else {
        Toaster({
          type: 'error',
          title: 'STOCK REQUEST',
          description: 'Something Went Wrong',
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.setState({
        isLoading: false,
      })
      this.props.handleCancel()
    }
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    const {data} = this.props

    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  reject_reason: '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-12'>
                          <InputText
                            input={{
                              value: values.reject_reason,
                              id: 'reject_reason',
                              name: 'reject_reason',
                            }}
                            placeholder='Enter Reason For Rejection'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('reject_reason', value)
                            }}
                            onBlur={handleBlur}
                            label='Reason For Rejection'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='custom-card-footer'>
                        <div className='float-right'>
                          <InputButtonComponent onClick={handleSubmit}>Submit</InputButtonComponent>
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
