import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setVendorOrderData: 'SET_VENDOR_ORDER_DATA',
  setVendorOrderDataTotal: 'SET_VENDOR_ORDER_DATA_TOTAL',
  setVendorOrderLoading: 'SET_VENDOR_ORDER_LOADER'
}

const initialOrderState: IOrderState = {
  vendorOrderData: undefined,
  vendorOrderDataTotal: undefined,
  vendorOrderLoading: false
}

export interface IOrderState {
  vendorOrderData?: any
  vendorOrderDataTotal?: number
  vendorOrderLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'vendororder', whitelist: ['vendorOrderData', 'vendorOrderDataTotal']},
    (state: IOrderState = initialOrderState, action: ActionWithPayload<IOrderState>) => {
    switch (action.type) {
      case actionTypes.setVendorOrderData: {
        const vendorOrderData = action.payload?.vendorOrderData
        return {...state, vendorOrderData}
      }
            
      case actionTypes.setVendorOrderDataTotal: {
        const vendorOrderDataTotal = action.payload?.vendorOrderDataTotal
        return {...state, vendorOrderDataTotal}
      }
        
      case actionTypes.setVendorOrderLoading: {
        const vendorOrderLoading = action.payload?.vendorOrderLoading
        return {...state, vendorOrderLoading}
      }


      default:
        return state
    }
  }
)

export const actions = {
  setVendorOrderData: (vendorOrderData: Array<any>) => ({type: actionTypes.setVendorOrderData, payload: {vendorOrderData : vendorOrderData}}),
  setVendorOrderDataTotal: (vendorOrderDataTotal: number) => ({ type: actionTypes.setVendorOrderDataTotal, payload: { vendorOrderDataTotal } }),
  setVendorOrderLoading: (vendorOrderLoading: boolean) => ({type: actionTypes.setVendorOrderLoading, payload: {vendorOrderLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setVendorOrderData, function* loginSaga() {
    yield put(actions.setVendorOrderLoading(false))
  })
}
