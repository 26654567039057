/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {OrderPage} from '../../component/order/OrderPage'
import {shallowEqual, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'
import {Toaster} from '../../modules/comman/components/ToasterNoti'

const OrderWrapper: FC = () => {
  const history = useHistory()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  useEffect(() => {}, [])

  return (
    <div>
      <OrderPage />
    </div>
  )
}

export {OrderWrapper}
