import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import EmailEditor from 'react-email-editor';
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputToggle} from '../../../modules/comman/formComponents/InputToggle'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import RichTextEditor from 'react-rte'

interface IRenderFormikForm {
  values: IStaticPageFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IStaticPageFormValues {
  slug: string
  title: string
  content: any
  id: string
}

interface IStaticPageProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export class EditStaticPageForm extends React.Component<IStaticPageProps, any> {
    editorRef: any = React.createRef<any>();
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      content: RichTextEditor.createEmptyValue(),
    }
  }

  public validateForm = (values: IStaticPageFormValues) => {
    const errors: any = {}

    if (!values.slug) {
      errors.slug = 'Slug is Required'
    }

    if (!values.title) {
      errors.title = 'Title is Required'
    }

    if (!values.content) {
      errors.content = 'Content is Required'
    }
    console.log(errors,"err")
    return errors
  }

  public handleSubmit = (values: IStaticPageFormValues, action: any) => {
    const {handleCancel , onSubmit} = this.props;
    try {  
      onSubmit(values);
      handleCancel?.();
    }
    catch (e) {
      console.log(e,"err")
      throw e
    }
  }

  async componentDidMount() {
    console.log('component did mount')
    if (this.props.data && this.props.data.content) {
      this.setState({
        content: RichTextEditor.createValueFromString(this.props.data.content, 'html'),
      })
    }
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  onContentChange = (value: any) => {
    this.setState({content: value})
  }

  public render() {
    const {data} = this.props;

    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  slug: data.slug ? data.slug : '',
                  title: data.title ? data.title : '',
                  content: data.content ? data.content : '',
                  id: data.id ? data.id : ''
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.slug,
                              id: 'slug',
                              name: 'slug',
                            }}
                            placeholder='Enter Slug'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('slug', value)
                            }}
                            onBlur={handleBlur}
                            label='Slug'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.title,
                              id: 'title',
                              name: 'title',
                            }}
                            placeholder='Enter Title'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('title', value)
                            }}
                            onBlur={handleBlur}
                            label='Title'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      {/* <div className='row'>
                        <EmailEditor ref={this.editorRef} />
                      </div> */}
       <div
                        className=''
                        style={{width: '100%', float: 'left', marginBottom: '20px'}}
                      >
                        Content
                        <RichTextEditor
                          value={this.state.content}
                          onChange={(e: any) => {
                            setFieldValue('content', e.toString('html'))
                            this.onContentChange(e)
                          }}
                          placeholder={'Content'}
                        />
                        {errors.content && touched.content ? (
                          <p
                            style={{
                              color: 'red',
                              fontSize: 'small',
                              margin: '0',
                            }}
                          >
                            {errors.content}
                          </p>
                        ) : null}
                      </div>
                      <div style={{marginBottom: '15px'}}></div>
                      <InputButtonComponent onClick={handleSubmit}>Edit</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
