import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export const GET_BANNER_URL = `${API_URL}api/v1/auth/admin/banner`
export const SEARCH_BANNER_URL = `${API_URL}api/v1/auth/admin/banner`
export const CREATE_BANNER_URL = `${API_URL}api/v1/auth/admin/banner`
export const EDIT_BANNER_URL = `${API_URL}api/v1/auth/admin/banner`
export const DELETE_BANNER_URL = `${API_URL}api/v1/auth/admin/banner`
export const CHANGE_STATUS_BANNER_URL = `${API_URL}api/v1/auth/admin/banner/status`

interface ListType {
  count?: any
  rows: any
}

// Get Order Data
export async function getBannerData(startIndex: number, limitNumber: number) {
  try {
    let bannerData: ListType = await axios.get(`${GET_BANNER_URL}?skip=${startIndex}&limit=${limitNumber}`)
    return bannerData
  } catch (e) {
    throw e
  }
}

// Search Order Data
export async function getSearchBannerData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let bannerData: ListType = await axios.get(`${SEARCH_BANNER_URL}?skip=${startIndex}&limit=${limitNumber}&searchText=${searchText}`)
    return bannerData
  } catch (e) {
    throw e
  }
}

// Create Order Data
export async function createBannerData(bannerObject: any) {
  try {
    let createBanner = await axios.post(`${CREATE_BANNER_URL}`, {
      ...bannerObject,
    })
    return createBanner
  } catch (e) {
    throw e
  }
}

// Delete Order Data
export async function deleteBannerData(bannerId: string) {
  try {
    let deleteOrderData = await axios.delete(`${DELETE_BANNER_URL}?id=${bannerId}`)
    return deleteOrderData
  } catch (e) {
    throw e
  }
}

// Change Status Order Data
export async function changeStatusBannerData(bannerId: string, flag: boolean) {
  try {
    let bannerData = await axios.put(`${CHANGE_STATUS_BANNER_URL}`, {
      id: bannerId,
      is_active: flag,
    })
    return bannerData
  } catch (e) {
    throw e
  }
}

// Edit Order Data
export async function editBannerData(bannerObj: any, id: string) {
  try {
    let editBannerData = await axios.put(`${EDIT_BANNER_URL}`, {
      ...bannerObj,
      id,
    })
    return editBannerData
  } catch (e) {
    throw e
  }
}
