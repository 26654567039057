/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ChatWraaper} from './container/PrivateChats'

export function ChatsPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/chats/list' exact={true} component={ChatWraaper} />
        <Redirect from='/chats' exact={true} to='/chats/list' />
        <Redirect to='/chats/list' />
      </Switch>
    </div>
  )
}
