/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'
import {Toaster} from '../../modules/comman/components/ToasterNoti'
import { SalesReportPage } from './../../component/salesReport/SalesReportPage';

const SalesReportWrapper: FC = () => {
  const history = useHistory()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  /**
   * useEffect check the user role and based on allow to access the current module or not
   * else redirect to the defined router
   */
  useEffect(() => {}, [])

  return (
    <div>
      <SalesReportPage />
    </div>
  )
}

export {SalesReportWrapper}
