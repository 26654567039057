/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getBuyRequestData} from '../redux/BuyRequestCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as buyRequest from '../redux/BuyRequestRedux'
import ListBuyRequestData from './ListBuyRequestData'
import {Tabs} from 'antd'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {Spin} from 'antd'

const {TabPane} = Tabs

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const [tabKey, setTabKey] = useState('1')
  const [isLoading, setIsLoading] = useState(false)

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  const {role, permission} = user

  const callBuyRequestData = async (status = '') => {
    try {
      console.log(status, 'call')
      setIsLoading(true)
      let buyRequestData = await getBuyRequestData(0, 10, status)
      if (buyRequestData.data) {
        await dispatch(buyRequest.actions.setBuyRequestLoading(true))
        // once buyRequest data set then loading will automatically off using saga middleware
        await dispatch(buyRequest.actions.setBuyRequestData(buyRequestData.data))
        await dispatch(buyRequest.actions.setBuyRequestDataTotal(buyRequestData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeTab = (key: string) => {
    console.log(key, typeof key, 'key')
    setTabKey(key)
    if (key === '3') {
      callBuyRequestData('canceled')
    } else if (key === '2') {
      callBuyRequestData('updated')
    }else if (key === '4') {
      callBuyRequestData('accepted')
    } else {
      callBuyRequestData('pending')
    }
  }

  useEffect(() => {
    {
      callBuyRequestData('pending')
    }
  }, [])

  return (
    // <div>
    //   <ListBuyRequestData />
    // </div>
    <div className='card p-5'>
      <Spin spinning={isLoading} tip='Loading...'>
        <div className='card-container'>
          <Tabs defaultActiveKey='1' onChange={onChangeTab} type='card'>
            <TabPane tab='Pending' key='1'>
              <ListBuyRequestData tabkey='pending' />
            </TabPane>
            {(user.role == 'super_admin' || user.role == 'sub_admin') && (
              <TabPane tab='In Progress' key='2'>
                <ListBuyRequestData tabkey='updated' />
              </TabPane>
            )}
            {(user.role == 'super_admin' || user.role == 'sub_admin') && (
              <TabPane tab='Rejected' key='3'>
                <ListBuyRequestData tabkey='canceled' />
              </TabPane>
            )}
             {(user.role == 'super_admin' || user.role == 'sub_admin') && (
              <TabPane tab='Completed' key='4'>
                <ListBuyRequestData tabkey='accepted' />
              </TabPane>
            )}
          </Tabs>
        </div>
      </Spin>
    </div>
  )
}

const ListWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/buyRequest/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/buyRequest/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.BUYREQUEST'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWrapper}
