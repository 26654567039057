import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_STATIC_PAGE_URL = `${API_URL}api/v1/auth/admin/static-page`
export const CREATE_STATIC_PAGE_URL = `${API_URL}api/v1/auth/admin/static-page`
export const EDIT_STATIC_PAGE_URL = `${API_URL}api/v1/auth/admin/static-page`
export const DELETE_STATIC_PAGE_URL = `${API_URL}api/v1/auth/admin/static-page`
export const CHANGE_STATUS_URL = `${API_URL}api/v1/auth/admin/static-page-status`

interface ListType {
  count?: any
  data: any
}

export async function getStaticPageData(startIndex: number, limitNumber: number, searchText? : string) {
  try {
    let orderData: ListType = await axios.get(`${GET_STATIC_PAGE_URL}?skip=${startIndex}&limit=${limitNumber}`)
    return orderData
  } catch (e) {
    throw e
  }
}

export async function createStaticPageData(pageData: any) {
  try {
    let createBanner = await axios.post(`${CREATE_STATIC_PAGE_URL}`, {
      ...pageData,
    })
    return createBanner
  } catch (e) {
    throw e
  }
}

export async function editStaticPageData(pageData: any) {
  try {
    let createBanner = await axios.put(`${EDIT_STATIC_PAGE_URL}`, {
      ...pageData,
    })
    return createBanner
  } catch (e) {
    throw e
  }
}

export async function deleteStaticPageData(id: string) {
  try {
    let deleteOrderData = await axios.delete(`${DELETE_STATIC_PAGE_URL}?id=${id}`)
    return deleteOrderData
  } catch (e) {
    throw e
  }
}

export async function changeStatusStaticPageData(id: string, flag: boolean) {
  try {
    let pageData = await axios.put(`${CHANGE_STATUS_URL}`, {
      id: id,
      is_active: flag,
    })
    return pageData
  } catch (e) {
    throw e
  }
}