import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setClientOfferData: 'SET_CLIENT_OFFER_DATA',
  setClientOfferDataTotal: 'SET_CLIENT_OFFER_DATA_TOTAL',
  setClientOfferLoading: 'SET_CLIENT_OFFER_LOADER',
}

const initialOrderState: IClientOfferState = {
  clientOfferData: undefined,
  clientOfferDataTotal: undefined,
  clientOfferLoading: false,
}

export interface IClientOfferState {
  clientOfferData?: any
  clientOfferDataTotal?: number
  clientOfferLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'clientoffer', whitelist: ['clientOfferData', 'clientOfferDataTotal']},
  (state: IClientOfferState = initialOrderState, action: ActionWithPayload<IClientOfferState>) => {
    switch (action.type) {
      case actionTypes.setClientOfferData: {
        const clientOfferData = action.payload?.clientOfferData
        return {...state, clientOfferData}
      }

      case actionTypes.setClientOfferDataTotal: {
        const clientOfferDataTotal = action.payload?.clientOfferDataTotal
        return {...state, clientOfferDataTotal}
      }

      case actionTypes.setClientOfferLoading: {
        const clientOfferLoading = action.payload?.clientOfferLoading
        return {...state, clientOfferLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setClientOfferData: (clientOfferData: Array<any>) => ({
    type: actionTypes.setClientOfferData,
    payload: {clientOfferData: clientOfferData},
  }),
  setClientOfferDataTotal: (clientOfferDataTotal: number) => ({
    type: actionTypes.setClientOfferDataTotal,
    payload: {clientOfferDataTotal},
  }),
  setClientOfferLoading: (clientOfferLoading: boolean) => ({
    type: actionTypes.setClientOfferLoading,
    payload: {clientOfferLoading},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setClientOfferData, function* loginSaga() {
    yield put(actions.setClientOfferLoading(false))
  })
}
