import React, { FC } from 'react';
import { Card } from 'antd'; // assuming you are using Ant Design for Card component

interface StatisticProps {
  title: string;
  value: number;
  titleColor: any
}

const StatisticCard: FC<StatisticProps> = ({ title, value, titleColor }) => {
  return (
        <div className='d-flex justify-content-between mb-2'>
          <div className= {`fw-bold ${titleColor}`}>{title.toUpperCase()}</div>
          <div className= {`fw-bolder ${titleColor}`} >{value ? value : 0}</div>
        </div>
  );
};

export default StatisticCard;
