/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import { getUsersData} from '../redux/UsersCRUD'
import {useDispatch} from 'react-redux'
import * as users from '../redux/UsersRedux'
import ListUsersData from './ListUsersData'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const [searchPagination, setSearchPagination] = useState({
    skip: 0,
    limit: 10,
  })

  const callUsersData = async () => {
    try {
      let usersData = await getUsersData(searchPagination.skip, searchPagination.limit)
      if (usersData.data) {
        await dispatch(users.actions.setUsersLoading(true))
        // once users data set then loading will automatically off using saga middleware
        await dispatch(users.actions.setUsersData(usersData.data))
        await dispatch(users.actions.setUsersDataTotal(usersData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callUsersData()
  }, [])

  return (
    <div>
      <ListUsersData />
    </div>
  )
}

const ListWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/users/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/users/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.CLIENT'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWrapper}
