import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setVendorStockData: 'SET_VENDOR_STOCK_DATA',
  setVendorStockDataTotal: 'SET_VENDOR_STOCK_DATA_TOTAL',
  setVendorStockLoading: 'SET_VENDOR_STOCK_LOADER'
}

const initialClientState: IVendorStockState = {
  vendorStockData: undefined,
  vendorStockDataTotal: undefined,
  vendorStockLoading: false
}

export interface IVendorStockState {
  vendorStockData?: any
  vendorStockDataTotal?: number
  vendorStockLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'vendorstock', whitelist: ['vendorStockData', 'vendorStockDataTotal']},
    (state: IVendorStockState = initialClientState, action: ActionWithPayload<IVendorStockState>) => {
    switch (action.type) {
      case actionTypes.setVendorStockData: {
        const vendorStockData = action.payload?.vendorStockData
        return {...state, vendorStockData}
      }
            
      case actionTypes.setVendorStockDataTotal: {
        const vendorStockDataTotal = action.payload?.vendorStockDataTotal
        return {...state, vendorStockDataTotal}
      }
        
      case actionTypes.setVendorStockLoading: {
        const vendorStockLoading = action.payload?.vendorStockLoading
        return {...state, vendorStockLoading}
      }


      default:
        return state
    }
  }
)

export const actions = {
  setVendorStockData: (vendorStockData: Array<any>) => ({type: actionTypes.setVendorStockData, payload: {vendorStockData : vendorStockData}}),
  setVendorStockDataTotal: (vendorStockDataTotal: number) => ({ type: actionTypes.setVendorStockDataTotal, payload: { vendorStockDataTotal } }),
  setVendorStockLoading: (vendorStockLoading: boolean) => ({type: actionTypes.setVendorStockLoading, payload: {vendorStockLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setVendorStockData, function* loginSaga() {
    yield put(actions.setVendorStockLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
