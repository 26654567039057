import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {PictureOutlined} from '@ant-design/icons'
import {Button} from 'antd'
import ListUploadedMediaItem from '../../../modules/comman/ListComponenets/ListUploadedMediaItem'
import {config} from '../../../comman/constants'
import {FilePdfOutlined, CalendarOutlined} from '@ant-design/icons'
import {Switch} from 'antd'

const PRIMARY_COLOR = config.buttonPrimaryColor

interface IRenderFormikForm {
  values: IImportInventoryFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IImportInventoryFormValues {
  offset?: string
  file_name: string
  replace_all?: any
}

interface IImportInventoryProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

export class VendorImportInventoryForm extends React.Component<IImportInventoryProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      viewFileUploadModal: false,
      isLoading: false,
      uploadMedia: [],
    }
  }

  viewFileUploadModalRef: {show: () => void} | undefined

  public validateForm = (values: IImportInventoryFormValues) => {
    const errors: any = {}

    if (!values.file_name) {
      errors.file_name = 'Please select file'
    }

    return errors
  }

  public handleSubmit = (values: IImportInventoryFormValues, action: any) => {
    // values.replace_all = values.replace_all == 'true' ? true : false
    if (!values.offset) {
      delete values.offset
    }
    this.props.onSubmit(values)

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public uploadMedia = (file_name: string, FileData: Array<any>, customFileData: Object) => {
    if (file_name && FileData.length >= 1) {
      this.setState({
        uploadMedia: FileData,
      })
    }
    //close modal popup
    this.setState({
      viewFileUploadModal: false,
    })
  }

  public showMediaModal = (params: any) => {
    this.setState(
      {
        viewFileUploadModal: true,
      },
      () => {
        this.viewFileUploadModalRef?.show?.()
      }
    )
  }

  public render() {
    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  offset: '',
                  file_name: '',
                  replace_all: '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='row g-5'>
                        <div
                          className='card col-md-6 col-12'
                          style={{
                            // paddingTop: '20px',
                            // paddingBottom: '20px',
                            padding: '20px',
                            filter: 'drop-shadow(rgba(0, 0, 0, 0.1) 5px 0px 15px)',
                          }}
                        >
                          <div style={{fontSize: '16px', fontWeight: 700, marginBottom: '10px'}}>
                            Direct Upload
                          </div>
                          <div className='text-muted' style={{marginBottom: '10px'}}>
                            Click
                            <a style={{color: 'black', fontWeight: 500}}> here </a> for detailed
                            instructions and acceptable values
                          </div>
                          <div className='sampleFileDownload' style={{marginBottom: '30px'}}>
                            <a href={config.downloadSampleFilePath} target='_blank'>
                              <Button icon={<FilePdfOutlined style={{fontSize: '18px'}} />}>
                                Download Sample File
                              </Button>
                            </a>
                          </div>
                          <div style={{fontSize: '14px', fontWeight: 'bold', marginBottom: '30px'}}>
                            Step 1 : Add your File
                          </div>
                          <div style={{marginBottom: '10px'}}>
                            {/* {this.state.viewFileUploadModal ? ( */}
                            <InputSingleFileUpload
                              // ref={(e: any) => {
                              //   this.viewFileUploadModalRef = e
                              // }}
                              buttonLabel={'Choose Stock File'}
                              onBlur={handleBlur}
                              error={errors}
                              touched={touched}
                              name='file_name'
                              input={{
                                id: 'import-inventory',
                                name: config.importStockUploadKey,
                                action: config.importStockUploadPath,
                                headers: {
                                  authorization: 'authorized-text',
                                },
                                accept: config.fileExtensionsForDropZone,
                                acceptMimeTypes: config.fileExtensionsMimeTypes,
                                mediaType: config.importStockUploadKey,
                                mediaModuleType: config.importStockUploadKey,
                              }}
                              onUpload={(
                                file_name: string,
                                FileData: Array<any>,
                                customFileData: Object
                              ) => {
                                this.uploadMedia(file_name, FileData, customFileData)
                                setFieldValue(
                                  'file_name',
                                  file_name && FileData.length >= 1 ? file_name : ''
                                )
                              }}
                            />
                               {values.file_name && 
                            this.state.uploadMedia &&
                            this.state.uploadMedia[0] &&
                            this.state.uploadMedia.length >= 1 ? (
                              <ListUploadedMediaItem
                                name={this.state.uploadMedia[0].response.file}
                                url={`${config.importStockPreviewPath}${this.state.uploadMedia[0].response.file}`}
                              />
                            ) : null}
                          </div>
                          <div
                            className='text-muted'
                            style={{marginBottom: '20px', fontWeight: 500}}
                          >
                            Accepted Files Types csv,xls,xlxs
                          </div>
                          <div style={{fontSize: '14px', fontWeight: 'bold'}}>Step 2 : Offset</div>
                          <div style={{marginBottom: '30px'}}>
                            <InputText
                              input={{
                                value: values.offset,
                                id: 'offset',
                                name: 'offset',
                              }}
                              placeholder='Enter offset'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('offset', value)
                              }}
                              onBlur={handleBlur}
                              label='Offset'
                              required={false}
                              error={errors}
                              touched={touched}
                            />
                          </div>
                          <div style={{marginBottom: '30px'}}>
                            <span style={{fontSize: '14px', fontWeight: 500}}>Replace All</span>
                            <Switch
                              defaultChecked={false}
                              checked={values.replace_all}
                              style={{
                                backgroundColor: values.replace_all ? PRIMARY_COLOR : '#bababa',
                                marginLeft: '15px',
                              }}
                              onChange={(value: any) => {
                                setFieldValue('replace_all', value)
                              }}
                            />
                          </div>
                          <Button style={{width: '100%'}} type='primary' onClick={handleSubmit}>
                            UPLOAD FILE
                          </Button>
                        </div>
                        <div className='col-md-6 col-12' style={{padding: '0 20px'}}>
                          <div className='row'>
                            <div
                              className='col-12 card'
                              style={{
                                filter: 'drop-shadow(rgba(0, 0, 0, 0.1) 5px 0px 15px)',
                                marginBottom: '10px',
                              }}
                            >
                              <div
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 700,
                                  paddingTop: '15px',
                                  paddingLeft: '15px',
                                  marginBottom: '10px',
                                }}
                              >
                                FTP
                              </div>
                              <div
                                className='text-muted'
                                style={{marginBottom: '10px', paddingLeft: '20px'}}
                              >
                                You can easily set up an automated upload to our FTP server by
                                requesting your own secure login details. Need assistance? A Diamond Company
                                rep would be more than happy to help.
                                <div
                                  className='bokkMeeting'
                                  style={{marginBottom: '20px', marginTop: '20px'}}
                                >
                                  <Button icon={<CalendarOutlined style={{fontSize: '18px'}} />}>
                                    Book a meeting
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <div
                              className='col-12 card'
                              style={{filter: 'drop-shadow(rgba(0, 0, 0, 0.1) 5px 0px 15px)'}}
                            >
                              <div
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 700,
                                  paddingTop: '15px',
                                  paddingLeft: '15px',
                                  marginBottom: '10px',
                                }}
                              >
                                API
                              </div>
                              <div
                                className='text-muted'
                                style={{marginBottom: '10px', paddingLeft: '20px'}}
                              >
                                If you have an API link available, this is the best way for you to
                                upload your stock to Diamond Company. Please send your link and instructions
                                to <b style={{color: 'black'}}>supply@diamondcompany.com</b>
                              </div>
                            </div>
                            <div
                              className='text-muted'
                              style={{marginTop: '10px', paddingLeft: '20px'}}
                            >
                              For any questions,please reach out to the Supplier Relations Team at
                              <b style={{color: 'black'}}> supply@diamondcompany.com</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
