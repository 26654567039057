import React from 'react'
import axios from 'axios'
import { config } from '../../../comman/constants'

// const API_URL = 'http://192.168.29.28:3002/'
const API_URL = config.chatConfigUrl || 'http://localhost:2806/'

interface audioResponse {
  file : string
  status: number
  message: string
}

export const GET_CHAT_USER_LIST_URL = `${API_URL}api/v1/auth/admin/chat-rooms`
export const UPLOAD_AUDIO_FROM_CHAT_URL = `${API_URL}file/upload/audio/file`

export async function getChatUserListData() {
    try {
      let userData = await axios.get(`${GET_CHAT_USER_LIST_URL}`)
      return userData
    } catch (e) {
      throw e
    }
  }

  export async function uploadChatAudio(formData: any) {
    try {
      let audioUploadResponse: audioResponse = await axios.post(`${UPLOAD_AUDIO_FROM_CHAT_URL}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return audioUploadResponse;
    } catch (e) {
      throw e
    }
  }