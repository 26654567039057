import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setUsersData: 'SET_USERS_DATA',
  setUsersDataTotal: 'SET_USERS_DATA_TOTAL',
  setUsersLoading: 'SET_USERS_LOADER'
}

const initialClientState: IUsersState = {
  usersData: undefined,
  usersDataTotal: undefined,
  usersLoading: false
}

export interface IUsersState {
  usersData?: any
  usersDataTotal?: number
  usersLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'users', whitelist: ['usersData', 'usersDataTotal']},
    (state: IUsersState = initialClientState, action: ActionWithPayload<IUsersState>) => {
    switch (action.type) {
      case actionTypes.setUsersData: {
        const usersData = action.payload?.usersData
        return {...state, usersData}
      }
            
      case actionTypes.setUsersDataTotal: {
        const usersDataTotal = action.payload?.usersDataTotal
        return {...state, usersDataTotal}
      }
        
      case actionTypes.setUsersLoading: {
        const usersLoading = action.payload?.usersLoading
        return {...state, usersLoading}
      }


      default:
        return state
    }
  }
)

export const actions = {
  setUsersData: (usersData: Array<any>) => ({type: actionTypes.setUsersData, payload: {usersData : usersData}}),
  setUsersDataTotal: (usersDataTotal: number) => ({ type: actionTypes.setUsersDataTotal, payload: { usersDataTotal } }),
  setUsersLoading: (usersLoading: boolean) => ({type: actionTypes.setUsersLoading, payload: {usersLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setUsersData, function* loginSaga() {
    yield put(actions.setUsersLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
