import {FC, useEffect, useState} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as vendorstock from './VendorStockRedux'
import {RootState} from '../../../../setup'

const mapState = (state: RootState) => ({vendorstock: state.vendorstock})
const connector = connect(mapState, vendorstock.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const VendorStockInit: FC<PropsFromRedux> = (props) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // We should request user by authToken before rendering the application
  useEffect(() => {}, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(VendorStockInit)
