import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import { InputNumberText } from '../../../modules/comman/formComponents/InputNumberText'
import { Toaster } from '../../../modules/comman/components/ToasterNoti'
import { changeAdminSettingApi } from '../../../modules/auth/redux/AuthCRUD'

interface IRenderFormikForm {
  values: ISettingFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface ISettingFormValues {
    recipients_name: string,
    bank_name: string,
    bank_account_number: number,
    bank_routing_number: number,
    bank_swift_bic_code: string,
    return_within: number
}

export class AdminBillingDetails extends React.Component<any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  public validateForm = (values: ISettingFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    if (!values.recipients_name) {
      errors.recipients_name = 'Recipients name is required'
    }

    if (!values.bank_name) {
      errors.bank_name = 'Bank name is required'
    }

    if (!values.bank_account_number) {
      errors.bank_account_number = 'Bank Account Number is required'
    }

    if (!values.bank_routing_number) {
      errors.bank_routing_number = 'Bank Routing Number is required'
    }

    if (!values.bank_swift_bic_code) {
        errors.bank_swift_bic_code = 'Bank Swift Bic Code is required'
      }
      
    if (!values.return_within) {
        errors.return_within = 'Return Within is required'
    }

    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = async(values: ISettingFormValues, action: any) => {
    try {
    console.log('Basic Value - ', values)
        let changeSettingsData = await changeAdminSettingApi(values)
       console.log("call")
        if (changeSettingsData && changeSettingsData.status == 200) {
        Toaster({
          type: 'success',
          title: 'SETTINGS',
          description: 'Updated Settings Successfully',
        })
        }
        // setTimeout(() => {
        //     window.location.reload()
        //   }, 100)
      } catch (e: any) {

      }

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    return (
      <>
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title'>Update Admin Settings</h3>
          </div>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                    recipients_name: '',
                    bank_name: '',
                    bank_account_number: NaN,
                    bank_routing_number: NaN,
                    bank_swift_bic_code: '',
                    return_within: NaN
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.recipients_name,
                              id: 'recipients_name',
                              name: 'recipients_name',
                            }}
                            placeholder='Enter Recipients Name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('recipients_name', value)
                            }}
                            onBlur={handleBlur}
                            label='Recipients Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.bank_name,
                              id: 'bank_name',
                              name: 'bank_name',
                            }}
                            placeholder='Enter Bank Name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('bank_name', value)
                            }}
                            onBlur={handleBlur}
                            label='Bank Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.bank_account_number,
                              id: 'bank_account_number',
                              name: 'bank_account_number',
                            }}
                            placeholder='Enter Bank Account Number'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('bank_account_number', value)
                            }}
                            onBlur={handleBlur}
                            label='Bank Account Number'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.bank_routing_number,
                              id: 'bank_routing_number',
                              name: 'bank_routing_number',
                            }}
                            placeholder='Enter Bank Routing Number'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('bank_routing_number', value)
                            }}
                            onBlur={handleBlur}
                            label='Bank Routing Number'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.bank_swift_bic_code,
                              id: 'bank_swift_bic_code',
                              name: 'bank_swift_bic_code',
                            }}
                            placeholder='Enter Bank Swift Bic Code'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('bank_swift_bic_code', value)
                            }}
                            onBlur={handleBlur}
                            label='Bank Swift Bic Code'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.return_within,
                              id: 'return_within',
                              name: 'return_within',
                            }}
                            placeholder='Enter Return Within'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('return_within', value)
                            }}
                            onBlur={handleBlur}
                            label='Return Within (days)'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <InputButtonComponent onClick={handleSubmit}>Submit</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </>
    )
  }
}
