/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ListWraaper} from './containers/List'
import PreviewOrderDetailsPage from './containers/PreviewOrderDetailsPage'

export function VendorOrderPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/vendor-order/list' exact={true} component={ListWraaper} />
        <Redirect from='/vendor-order' exact={true} to='/vendor-order/list' />
        <Route path='/vendor-order/preview/:id' exact={true} component={PreviewOrderDetailsPage} />
        <Redirect to='/vendor-order/list' />
      </Switch>
    </div>
  )
}
