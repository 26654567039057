/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ListWraaper} from './containers/List'
// import PreviewOrderDetailsPage from './containers/PreviewOrderDetailsPage'

export function ReturnOrderPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/returnorder/list' exact={true} component={ListWraaper} />
        <Redirect from='/returnorder' exact={true} to='/returnorder/list' />
        {/* <Route path='/returnorder/preview/:id' exact={true} component={PreviewreturnorderDetailsPage} /> */}
        <Redirect to='/returnorder/list' />
      </Switch>
    </div>
  )
}
