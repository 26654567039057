import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setChatsData: 'SET_CHATS_DATA',
  setChatsDataTotal: 'SET_CHATS_DATA_TOTAL',
  setChatsLoading: 'SET_CHATS_LOADER'
}

const initialClientState: IChatState = {
  chatsData: undefined,
  chatsDataTotal: undefined,
  chatsLoading: false
}

export interface IChatState {
  chatsData?: any
  chatsDataTotal?: number
  chatsLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'chats', whitelist: ['chatsData', 'chatsDataTotal']},
    (state: IChatState = initialClientState, action: ActionWithPayload<IChatState>) => {
    switch (action.type) {
      case actionTypes.setChatsData: {
        const chatsData = action.payload?.chatsData
        return {...state, chatsData}
      }
            
      case actionTypes.setChatsDataTotal: {
        const chatsDataTotal = action.payload?.chatsDataTotal
        return {...state, chatsDataTotal}
      }
        
      case actionTypes.setChatsLoading: {
        const chatsLoading = action.payload?.chatsLoading
        return {...state, chatsLoading}
      }


      default:
        return state
    }
  }
)

export const actions = {
  setChatsData: (chatsData: Array<any>) => ({type: actionTypes.setChatsData, payload: {chatsData : chatsData}}),
  setChatsDataTotal: (chatsDataTotal: number) => ({ type: actionTypes.setChatsDataTotal, payload: { chatsDataTotal } }),
  setChatsLoading: (chatsLoading: boolean) => ({type: actionTypes.setChatsLoading, payload: {chatsLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setChatsData, function* loginSaga() {
    yield put(actions.setChatsLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
