/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import { getSubAdminData} from '../redux/SubAdminCRUD'
import {useDispatch} from 'react-redux'
import * as subadmin from '../redux/SubAdminRedux'
import ListSubAdminData from './ListSubAdminData'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const [searchPagination, setSearchPagination] = useState({
    skip: 0,
    limit: 10,
  })

  const callSubAdminData = async () => {
    try {
      let usersData = await getSubAdminData(searchPagination.skip, searchPagination.limit)
      if (usersData.data) {
        await dispatch(subadmin.actions.setSubAdminLoading(true))
        // once subadmin data set then loading will automatically off using saga middleware
        await dispatch(subadmin.actions.setSubAdminData(usersData.data))
        await dispatch(subadmin.actions.setSubAdminDataTotal(usersData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callSubAdminData()
  }, [])

  return (
    <div>
      <ListSubAdminData />
    </div>
  )
}

const ListWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/subadmin/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/subadmin/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.SUBADMIN'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWrapper}
