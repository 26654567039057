/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {changeEmailApi} from '../../../modules/auth/redux/AuthCRUD'
import {useHistory} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'

const initialValues = {
  email: '',
}

const changePasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

const ChangeEmail: FC = () => {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [hasErrorsString, setHasErrorsString] = useState<string | undefined>(undefined)

  const history = useHistory()

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(async () => {
        try {
          let changeData = await changeEmailApi(values.email)
          console.log('!!!!!changeData', changeData)
          setHasErrors(false)
          setLoading(false)
          setTimeout(() => {
            window.location.reload()
          }, 100)
          if (changeData && changeData.status == 200) {
          Toaster({
            type: 'success',
            title: 'SETTINGS',
            description: 'Email Changed Successfully',
          })
          }
        } catch (e: any) {
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          setStatus('Please try again later')
          setHasErrorsString(
            typeof e === 'string'
              ? e
              : typeof e.message === 'string'
              ? e.message
              : 'Please check inputs and try again later'
          )
        }
      }, 1000)
    },
  })

  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>Change Email</h3>
        </div>
        <div className='card-body'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
          >
            {/* begin::Title */}
            {hasErrors === true && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  {/* Sorry, looks like there are some errors detected, please try again. */}
                  {hasErrorsString}
                </div>
              </div>
            )}

            {hasErrors === false && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>Email Changed SuccessFully</div>
              </div>
            )}
            {/* end::Title */}

            {/* begin::Form group */}
            <div className='d-flex fv-row mb-10'>
              <div className='col-md-6 col-12'>
                <div className='d-flex justify-content-between mt-n5'>
                  <div className='d-flex flex-stack mb-2'>
                    {/* begin::Label */}
                    <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                    {/* end::Label */}
                  </div>
                </div>
                <input
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                )}
              </div>
            </div>

            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-start pb-lg-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-primary fw-bolder me-4'
              >
                <span className='indicator-label'>Submit</span>
                {loading && (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Form group */}
          </form>
        </div>
      </div>
    </div>
  )
}

export {ChangeEmail}
