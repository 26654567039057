/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createStaticPageData, getStaticPageData} from '../redux/StaticCRUD'
import * as staticpage from '../redux/StaticRedux'
import {CreateStaticPageForm} from './CreateStaticPageForm'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  // call initial data for Form
  const callInitialData = async () => {
    try {
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callBannerData = async () => {
    try {
      let bannerData = await getStaticPageData(0, 10)
      if (bannerData.data) {
        await dispatch(staticpage.actions.setStaticLoading(true))
        // once staticpage data set then loading will automatically off using saga middleware
        await dispatch(staticpage.actions.setStaticData(bannerData.data))
        await dispatch(staticpage.actions.setStaticDataTotal(bannerData.count))
        history.push('/static/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: any) => {
    try {
      await createStaticPageData(values)
      Toaster({
        type: 'success',
        title: 'STATIC PAGE',
        description: 'Static Page Created Successfully',
      })
      await callBannerData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const handleCancel = () => {
    history.push('/static/list')
  }
  return (
    <div>
      <CreateStaticPageForm onSubmit={(values: any) => addSubmit(values)} handleCancel = {() => {handleCancel()}} />
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'Create',
            path: '/static/create',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'Create',
            path: '/static/create',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.STATICPAGE'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
