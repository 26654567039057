import React from 'react'
import axios from 'axios'
import {OrderModal} from '../models/OrderModal'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_ORDER_URL = `${API_URL}api/v1/auth/admin/order`
export const PREVIEW_ORDER_DETAILS = `${API_URL}api/v1/auth/admin/order-details`
export const EDIT_ORDER_STATUS_URL = `${API_URL}api/v1/auth/admin/order-status`
export const GET_VENDOR_ORDER_URL = `${API_URL}api/v1/auth/admin/vendor-order`
export const UPDATE_ORDER_INVOICE_URL = `${API_URL}api/v1/auth/admin/update-invoice`
export const CANCEL_ORDER_URL = `${API_URL}api/v1/auth/admin/cancel-order`

interface ListType {
  count?: any
  data: any
}

// Get Order Data
export async function getOrderData(startIndex: number, limitNumber: number, status?: string) {
  try {
    let getOrderAPIPath = ''
    if (status !== undefined && status !== '') {
      getOrderAPIPath = `${GET_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&order_status=${status}`
    } else {
      getOrderAPIPath = `${GET_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&order_status=PENDING`
    }
    let orderData: ListType = await axios.get(getOrderAPIPath)
    // let orderData: ListType = await axios.get(`${GET_ORDER_URL}`)
    return orderData
  } catch (e) {
    throw e
  }
}

export async function getOrderStockDetails(req_id: string) {
  try {
    let orderDetailsData: ListType = await axios.get(`${PREVIEW_ORDER_DETAILS}?id=${req_id}`)
    return orderDetailsData
  } catch (e) {
    throw e
  }
}

export async function changeOrderStausDataApi(values: any) {
  let editUserData = await axios.put(`${EDIT_ORDER_STATUS_URL}`, {
    ...values,
  })
  return editUserData
}

export async function getVendorStockDataForOrder(order_id: string) {
  try {
    let orderData: ListType = await axios.get(`${GET_VENDOR_ORDER_URL}?order_id=${order_id}`)
    return orderData
  } catch (e) {
    throw e
  }
}

export async function invoiceOrderApi(values: any) {
  let updateInvoiceData = await axios.put(`${UPDATE_ORDER_INVOICE_URL}`, {
    ...values,
  })
  return updateInvoiceData
}

export async function getOrderDataForCsv(id?: any) {
  try {
   let vendorStockDetailsData = await axios.get(`${GET_VENDOR_ORDER_URL}?order_id=${id}`)
   return vendorStockDetailsData;
  } catch (e) {
    throw e
  }
}

export async function cancelOrderDataApi (id: any) {
try {
let cancelledOrderData = await axios.put(`${CANCEL_ORDER_URL}`,{
  id
})
return cancelledOrderData;
}
catch (e) {
  console.log(e,"error! in cancel Order Api")
}
}