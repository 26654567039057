/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as subadmin from '../redux/SubAdminRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {
     changeStatusSubAdminData,
     deleteSubAdminData,
     editSubAdminData,
     getSearchSubAdminData,
     getSubAdminData,
} from '../redux/SubAdminCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import { EditSubAdminForm } from './EditSubAdminForm'
// import { EditUserForm } from './EditUserForm'
// import {EditUserForm} from './EditUserForm'
// import {IClientFormValues} from './CreateClientForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  subAdminData: any
  subAdminDataTotal: number
  subAdminLoading: boolean
  setListSubAdminData: (subAdminData: any) => void
  setListSubAdminDataTotal: (subAdminDataTotal: number) => void
  setSubAdminLoading: (subAdminLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  adminSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListSubAdminData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      adminSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListSubAdminData(pagination)
    )
  }

  public getListSubAdminData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listSubAdminSearchData(start, end)
        } else {
          this.listSubAdminData(start, end)
        }
      }
    )
  }

  public listSubAdminData = async (start: number, end: any) => {
    const {setSubAdminLoading} = this.props
    try {
      const {setListSubAdminData, setListSubAdminDataTotal} = this.props
      await setSubAdminLoading(true)
      let subAdminData = await getSubAdminData(start, end)
      if (subAdminData.data) {
        await setListSubAdminData(subAdminData.data)
        await setListSubAdminDataTotal(subAdminData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setSubAdminLoading(false)
    }
  }

  public listSubAdminSearchData = async (start: number, end: any) => {
    const {setSubAdminLoading} = this.props
    try {
      const {setListSubAdminData, setListSubAdminDataTotal} = this.props
      const {searchText} = this.state

      await setSubAdminLoading(true)
      let subAdminData = await getSearchSubAdminData(start, end, searchText)
      if (subAdminData.data) {
        await setListSubAdminData(subAdminData.data)
        await setListSubAdminDataTotal(subAdminData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setSubAdminLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
            this.listSubAdminSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateSubAdmin = () => {
    const {history} = this.props
    history.push('/subadmin/create')
  }

  public deleteSubAdmin = async (item: any) => {
    const {setSubAdminLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setSubAdminLoading(true)
      let adminData = await deleteSubAdminData(item.id)

      if(adminData && adminData.status == 200) {
        Toaster({
          type: 'success',
          title: 'SUB ADMIN',
          description: 'Deleted SuccessFully',
        })  
      }
      else {
        Toaster({
          type: 'error',
          title: 'SUB ADMIN',
          description: 'Failed TO Delete Admin',
        })  
      }
      if (searchText != null && searchText !== '') {
        this.listSubAdminSearchData(startIndex, limitNumber)
        return
      }
      this.listSubAdminData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setSubAdminLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setSubAdminLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setSubAdminLoading(true)
      await changeStatusSubAdminData(item.id, checked)

      Toaster({
        type: 'success',
        title: 'SUB ADMIN',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listSubAdminSearchData(startIndex, limitNumber)
        return
      }
      this.listSubAdminData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setSubAdminLoading(false)
    }
  }

  public onEditSubmit = async (values: any) => {
    const {setSubAdminLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setSubAdminLoading(true)
      let editAdmin = await editSubAdminData(values)

      if(editAdmin && editAdmin.status == 200) {
        Toaster({
          type: 'success',
          title: 'SUB ADMIN',
          description: 'Edited SuccessFully',
        }) 
      }
      else {
        Toaster({
          type: 'error',
          title: 'SUB ADMIN',
          description: 'Failed TO Edit Admin',
        })  
      }

      if (searchText != null && searchText !== '') {
        this.listSubAdminSearchData(startIndex, limitNumber)
        return
      }
      this.listSubAdminData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setSubAdminLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      adminSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      adminSelectedData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Full Name',
      key: 'Full Name',
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.first_name && item.last_name
                ? `${item.first_name} ${item.last_name}`
                : item.first_name
                ? item.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Email',
      key: 'email',
      width: 250,
      render: (text: any, item: any, index: number) => {
        return <div>{item.email ? item.email : '-'}</div>
      },
    },
    // {
    //   title: 'Username',
    //   key: 'username',
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.username ? item.username : '-'}</div>
    //   },
    // },
    // {
    //   title: 'Company Name',
    //   key: 'company_name',
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.company_name ? item.company_name : '-'}</div>
    //   },
    // },
    {
      title: 'Role',
      key: 'role',
      render: (text: any, item: any, index: number) => {
        return <div>{item.role ? item.role : '-'}</div>
      },
    },
    {
      title: 'isActive',
      key: 'isActive',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.isActive != null ? (
                  <Switch
                    checked={item.isActive}
                    style={{
                      backgroundColor: item.isActive ? PRIMARY_COLOR : '#bababa',
                    }}
                    onChange={(checked: boolean) => {
                      //call onChange Method with item
                      this.onChangeStatus(item, checked)
                    }}
                  />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete subadmin api
                    this.deleteSubAdmin(item)
                  }}
                  title='Are you sure you want to delete sub admin?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {subAdminData, subAdminDataTotal, subAdminLoading} = this.props
    const {searchText, adminSelectedData} = this.state

    return (
      <div>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Sub admin'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateSubAdmin()
                  }}
                >
                  Add Sub Admin
                </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  paginationConfig={{
                    total: subAdminDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={subAdminLoading}
                  dataSource={subAdminData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={false}
                  // scrollWidth={1200}
                />
                <div>
                  <CommanModal
                    show={adminSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit Admin'}
                    submitText={'Save Changes'}
                    isScrollable={true}
                  >
                    <div>
                      {adminSelectedData && (
                        <EditSubAdminForm
                          data={adminSelectedData}
                          onSubmit={(values: any, userId: string) => {
                            this.onEditSubmit(values)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  subAdminData: state.subadmin.subAdminData,
  subAdminDataTotal: state.subadmin.subAdminDataTotal,
  subAdminLoading: state.subadmin.subAdminLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListSubAdminData: (subAdminData: any) => {
      dispatch(subadmin.actions.setSubAdminData(subAdminData))
    },
    setListSubAdminDataTotal: (subAdminDataTotal: number) => {
      dispatch(subadmin.actions.setSubAdminDataTotal(subAdminDataTotal))
    },
    setSubAdminLoading: (subAdminLoading: boolean) => {
      dispatch(subadmin.actions.setSubAdminLoading(subAdminLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListSubAdminData))
