/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
// import {CreateWrapper} from './containers/CreateInventory'
import {ImportWrapper} from './containers/ImportInventory'
import {ListWrapper} from './containers/List'
import {PreviewWrapper} from './containers/Preview'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'

export function InventoryPage() {
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  console.log(user, 'admin')
  const {role, permission} = user

  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        {/* <Route path='/inventory/create' exact={true} component={CreateWrapper} /> */}
        {role !== "sub_admin" && <Route path='/inventory/import' exact={true} component={ImportWrapper} />}
        <Route path='/inventory/list' exact={true} component={ListWrapper} />
        {/* <Route path='/inventory/twin' exact={true} component={ListTwinWrapper} />
        <Route path='/inventory/matrix' exact={true} component={ListMatrixWrapper} /> */}
        <Route path='/inventory/preview/:id' component={PreviewWrapper} />
        <Redirect from='/inventory' exact={true} to='/inventory/list' />
        <Redirect to='/inventory/list' />
      </Switch>
    </div>
  )
}
