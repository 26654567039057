import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import moment from 'moment'
import {Tag, Alert, Button} from 'antd'
import {config} from '../../../comman/constants'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
  returnStockString,
} from '../../../comman/helper'
// import {getShipMentPriceList} from '../../shipmentPrice/redux/ShipmentPriceCRUD'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
// import {editOrderStatusData} from '../redux/OrderCRUD'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {getVendorOrderStockDetails} from '../redux/OrderCRUD'
import {withRouter, RouteComponentProps} from 'react-router-dom'
import {Spin} from 'antd'

interface IPreviewOrderProps extends RouteComponentProps<any> {
  handleCancel?: any
  loginUserData?: any
}

const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
class PreviewOrderDetailsPage extends React.Component<IPreviewOrderProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      orderRequest: {},
      shipmentAddressDataState: {},
      billingAddressDataState: {},
      isLoading: false,
      orderStatusState: '',
      failureReasonState: '',
      showErrorFailureReason: false,
      showError: false,
      viewFileUploadModal: false,
      uploadMedia: [],
      isFileLinked: false,
      file_url: '',
      orderStatusData: [
        {
          label: 'Paid',
          value: 'PAID',
        },
        {
          label: 'Failed',
          value: 'FAILED',
        },
      ],
    }
  }

  totalPrice = 0
  totalDiscount = 0
  shipMentPrice = 0

  viewFileUploadModalRef: {show: () => void} | undefined

  public uploadMedia = (fileName: string, FileData: Array<any>, customFileData: Object) => {
    if (fileName && FileData.length >= 1) {
      this.setState({
        uploadMedia: FileData,
      })
      this.setState({
        viewFileUploadModal: false,
        isFileLinked: false,
        // file_url: `${appAPIURL}order/invoice/${fileName}`,
        file_url: fileName,
      })
      Toaster({
        type: 'success',
        title: 'Order',
        description: 'Invoice Uploaded Successfully',
      })
      console.log('!!!!!!!!!!!uploadMedia')
    }
    //close modal popup
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  componentDidMount() {
    this.callInitialData()
  }

  // call initial data for Form
  public callInitialData = async () => {
    try {
      //   let shipMentList = await getShipMentPriceList()
      //   if (shipMentList.data) {
      //     this.setState({shipmentData: shipMentList.data})
      //   }
      this.setState({
        isLoading: true,
      })
      const {match} = this.props
      const itemId = match.params.id

      let stockDetails: any = await getVendorOrderStockDetails(itemId)

      this.setState({
        orderRequest: stockDetails.data,
        data: stockDetails.data.buy_request_details.stock_ids,
        clientDataState: stockDetails.data.user ? stockDetails.data.user : {},
        shipmentAddressDataState: stockDetails.data.shipping_address
          ? stockDetails.data.shipping_address
          : {},
        billingAddressDataState: stockDetails.data.billing_address
          ? stockDetails.data.billing_address
          : {},
      })
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  public calculateTotal = () => {
    const {data} = this.state
    if (data && data.length >= 1) {
      this.totalPrice = 0
      this.totalDiscount = 0
      data.map((invItem: any) => {
        this.totalPrice += invItem.stock.final_price
        this.totalDiscount += invItem.stock.discounts
      })
    }
    return returnPriceInThousandFormatWithCurrencySign(this.totalPrice)
  }

  public calculateAvgDiscount = () => {
    const {data} = this.state
    return returnPriceInThousandFormat(this.totalDiscount / data.length)
  }

  public calculateShipMent = () => {
    const {shipmentData} = this.state

    if (shipmentData && shipmentData.length >= 1) {
      let getShipMentData = shipmentData.find(
        (shipItem: any) =>
          shipItem.from_number < this.totalPrice && shipItem.to_number > this.totalPrice
      )
      if (getShipMentData) {
        this.shipMentPrice = getShipMentData.price
      }
    }
    return returnPriceInThousandFormatWithCurrencySign(this.shipMentPrice)
  }

  public calculateGrandTotal = () => {
    let grandTotal = this.shipMentPrice + this.totalPrice
    return returnPriceInThousandFormatWithCurrencySign(grandTotal)
  }

  public handleEditOrderSubmit = async () => {
    try {
      if (!this.state.orderStatusState || this.state.orderStatusState == '') {
        Toaster({
          type: 'error',
          title: 'Order',
          description: 'Please Select Order Status',
        })
        this.setState({
          showError: true,
        })
        return
      } else if (
        this.state.orderStatusState == 'FAILED' &&
        (!this.state.failureReasonState || this.state.failureReasonState == '')
      ) {
        Toaster({
          type: 'error',
          title: 'Order',
          description: 'Please Enter Failure Reason',
        })
        this.setState({
          showErrorFailureReason: true,
        })
        return
      } else if (
        this.state.orderStatusState == 'PAID' &&
        (!this.state.file_url || this.state.file_url == '')
      ) {
        Toaster({
          type: 'error',
          title: 'Order',
          description: 'Please Upload Invoice',
        })
        return
      }
      //   await editOrderStatusData(
      //     data._id,
      //     this.state.orderStatusState,
      //     this.state.failureReasonState,
      //     this.state.file_url
      //   )
      Toaster({
        type: 'success',
        title: 'Order',
        description: 'Order Status changed successFully',
      })
      this.props.handleCancel()
    } catch (e) {
      throw e
    }
  }

  public render() {
    const {loginUserData} = this.props
    const {data, orderRequest, billingAddressDataState, shipmentAddressDataState, isLoading} =
      this.state

    return (
      <div className='card card-custom'>
        <Spin spinning={isLoading} tip='Loading...'>
          {/* <div className='alert alert-primary text-center font-weight-bold' role='alert'>
            {'Please check your mail for further process'}
          </div> */}
          <div className='card-body'>
            <div>
              <div className='row mb-10'>
                <div className='col-md-6 col-lg-6 col-sm-12'>
                  <h3>
                    {'Order Details'}
                    {` (${orderRequest.order_code && returnStockId(orderRequest.order_code)})`}
                  </h3>
                </div>
                <div className='col-md-3 col-lg-3 col-sm-12'>
                  <strong>
                    {orderRequest?.order_status && (
                      <Tag
                        className='p-2'
                        color={
                          config.statusTagColor[orderRequest.order_status]
                            ? config.statusTagColor[orderRequest.order_status]
                            : 'default'
                        }
                      >
                        {orderRequest.order_status}
                      </Tag>
                    )}
                  </strong>
                </div>
                <div className='col-md-3 col-lg-3 col-sm-12'>
                  <strong>
                    {orderRequest.createdAt
                      ? moment(orderRequest.createdAt).format('DD-MM-YYYY')
                      : '-'}
                  </strong>
                </div>
              </div>
              <div className='form-group'>
                <div className='row'>
                  <div className='col-md-2 col-lg-2 col-sm-12 font-weight-bold text-muted'>
                    <label>{'Order Code:'}</label>
                  </div>
                  <div className='col-md-4 col-lg-4 col-sm-12'>
                    <label>
                      {orderRequest.order_code ? returnStockId(orderRequest.order_code) : '-'}
                    </label>
                  </div>

                  <div className='col-md-2 col-lg-2 col-sm-12 font-weight-bold text-muted'>
                    <label>{'Order Date:'}</label>
                  </div>
                  <div className='col-md-4 col-lg-4 col-sm-12'>
                    <label>
                      {orderRequest.createdAt
                        ? moment(orderRequest.createdAt).format('DD-MM-YYYY')
                        : '-'}
                    </label>
                  </div>
                </div>
                <>
                  <div className='row'>
                    <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                      <label>{'Name:'}</label>
                    </div>
                    <div className='col-lg-4'>
                      <label>
                        {orderRequest &&
                        orderRequest?.user_details &&
                        orderRequest?.user_details?.first_name &&
                        orderRequest?.user_details?.last_name
                          ? `${orderRequest.user_details.first_name} ${orderRequest.user_details.last_name}`
                          : orderRequest?.user_details?.first_name
                          ? orderRequest?.user_details?.first_name
                          : '-'}
                      </label>
                    </div>
                    <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                      <label>{'Company:'}</label>
                    </div>
                    <div className='col-lg-4'>
                      <label>
                        {orderRequest &&
                        orderRequest?.user_details &&
                        orderRequest?.user_details?.business_entity_name
                          ? orderRequest.user_details.business_entity_name
                          : '-'}
                      </label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                      <label>{'Email:'}</label>
                    </div>
                    <div className='col-lg-4'>
                      <label>
                        {orderRequest &&
                        orderRequest?.user_details &&
                        orderRequest?.user_details?.email
                          ? orderRequest.user_details.email
                          : '-'}
                      </label>
                    </div>
                  </div>
                </>
              </div>
              <div className='row border-bottom'>
                <h3 className='mb-10'>{'Stock Details'}</h3>
                <div className='col-lg-1 text-dark font-weight-bold text-muted'>{'Stock Id'}</div>
                <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                  {'Vendor Name'}
                </div>
                <div className='col-lg-5 text-dark font-weight-bold text-muted'>{'Details'}</div>
                <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                  <div className='text-right'>
                    <label>{'Discount'}</label>
                  </div>
                </div>
                <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                  <div className='text-right'>
                    <label>{'$/Ct'}</label>
                  </div>
                </div>
                <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                  <div className='text-right'>
                    <label>{'Price'}</label>
                  </div>
                </div>
              </div>
              <div style={{maxHeight: '70vh', overflowX: 'hidden', overflowY: 'auto'}}>
                {data &&
                  !!data.length &&
                  data.map(
                    (invItem: any, index: number) =>
                      invItem && (
                        <div className='row mt-10' key={index}>
                          <div className='col-lg-1 text-dark font-weight-bold'>
                            <label>
                              {/* <a
                                href={`/inventory/preview/${invItem.stock_id._id}`}
                                target='_blank'
                              > */}
                                {returnStockId(invItem.stock.stock_id)}
                              {/* </a> */}
                            </label>
                          </div>
                          <div className='col-lg-2 text-dark font-weight-bold'>
                            <div className='text-left'>
                              <label>
                                {/* Vendor Name */}
                                </label>
                            </div>
                          </div>
                          <div className='col-lg-5 text-dark font-weight-bold'>
                            <label>{returnStockString(invItem.stock)}</label>
                          </div>
                          <div className='col-lg-1 text-dark font-weight-bold'>
                            <div className='text-right'>
                              <label>{invItem.stock.discounts}</label>
                            </div>
                          </div>
                          <div className='col-lg-1 text-dark font-weight-bold'>
                            <div className='text-right'>
                              <label>{invItem.stock.price_per_caret}</label>
                            </div>
                          </div>
                          <div className='col-lg-2 text-dark font-weight-bold'>
                            <div className='text-right'>
                              <label>
                                  {returnPriceInThousandFormatWithCurrencySign(
                                    invItem.stock.final_price
                                  )}
                                </label>
                            </div>
                          </div>
                        </div>
                      )
                  )}
              </div>
              <div className='row border-top mt-10'>
                <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                  <label>{'Sub Total:'}</label>
                </div>
                <div className='col-lg-2 text-dark font-weight-bold'>
                  <div className='text-right'>
                    <label>{this.calculateTotal()} </label>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                  <label>{'Discount:'}</label>
                </div>
                <div className='col-lg-2 text-dark font-weight-bold'>
                  <div className='text-right'>
                    <label>{`${this.calculateAvgDiscount()}(%)`} </label>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                  <label>{'Shipment Price:'}</label>
                </div>
                <div className='col-lg-2 text-dark font-weight-bold'>
                  <div className='text-right'>
                    <label>{this.calculateShipMent()} </label>
                  </div>
                </div>
              </div>
              <div className='row border-bottom'>
                <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                  <label>{'Total:'}</label>
                </div>
                <div className='col-lg-2 text-dark font-weight-bold'>
                  <div className='text-right'>
                    <label>{this.calculateGrandTotal()} </label>
                  </div>
                </div>
              </div>
              <div className='row  mt-6'>
                <div className='col-lg-6 col-md-6 col-sm-12 mb-10 ml-2 text-muted'>
                  <h3 className='mb-2'>{'Billing Address'}</h3>
                  <span>
                    {billingAddressDataState && (
                      <span
                        className='text-dark font-weight-normal text-muted'
                        style={{fontSize: '14px'}}
                      >
                        {/* {billingAddressDataState.address_line_1
                            ? ` ${billingAddressDataState.address_line_1},`
                            : ''}
                          {billingAddressDataState.address_line_2
                            ? ` ${billingAddressDataState.address_line_2},`
                            : ''} */}
                        {billingAddressDataState.street
                          ? ` ${billingAddressDataState.street},`
                          : ''}
                        {billingAddressDataState.city ? ` ${billingAddressDataState.city},` : ''}
                        {billingAddressDataState.state ? ` ${billingAddressDataState.state},` : ''}
                        {billingAddressDataState.country
                          ? ` ${billingAddressDataState.country} -`
                          : ''}
                        {billingAddressDataState.postalCode
                          ? ` ${billingAddressDataState.postalCode}`
                          : ''}
                      </span>
                    )}
                  </span>
                </div>
                <div className='col-lg-5 col-md-5 col-sm-12 mb-10'>
                  <h3 className='mb-2'>{'Shipping Address'}</h3>
                  <span>
                    {shipmentAddressDataState && (
                      <div
                        className='text-dark font-weight-normal text-muted'
                        style={{fontSize: '14px'}}
                      >
                        {/* {shipmentAddressDataState.address_line_1
                            ? ` ${shipmentAddressDataState.address_line_1},`
                            : ''}
                          {shipmentAddressDataState.address_line_2
                            ? ` ${shipmentAddressDataState.address_line_2},`
                            : ''} */}
                        {shipmentAddressDataState.street
                          ? ` ${shipmentAddressDataState.street},`
                          : ''}
                        {shipmentAddressDataState.city ? ` ${shipmentAddressDataState.city},` : ''}
                        {shipmentAddressDataState.state
                          ? ` ${shipmentAddressDataState.state},`
                          : ''}
                        {shipmentAddressDataState.country
                          ? ` ${shipmentAddressDataState.country} -`
                          : ''}
                        {shipmentAddressDataState.postalCode
                          ? ` ${shipmentAddressDataState.postalCode}`
                          : ''}
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    )
  }
}

export default withRouter(PreviewOrderDetailsPage)
