import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

const GET_DECLINE_STOCK_URL = `${API_URL}api/v1/auth/admin/vendor-rejected-requests`

export async function getDeclineStockData (skip: any , limit: any) {
    try {
        let declineStockData = await axios.get(`${GET_DECLINE_STOCK_URL}?skip=${skip}&limit=${limit}`)
        return declineStockData;
    }
    catch (e) {
        console.log(e,"error!!!")
    }
}