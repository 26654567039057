import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {InventoryModal} from '../models/InventoryModal'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setInventoryData: 'SET_INVENTORY_DATA',
  setInventoryDataTotal: 'SET_INVENTORY_DATA_TOTAL',
  setTwinInventoryData: 'SET_TWIN_INVENTORY_DATA',
  setMoreTwinInventoryData: 'SET_MORE_TWIN_INVENTORY_DATA',
  setTwinInventoryDataTotal: 'SET_TWIN_INVENTORY_DATA_TOTAL',
  setMatrixInventoryData: 'SET_MATRIX_INVENTORY_DATA',
  setMatrixInventoryDataTotal: 'SET_MATRIX_INVENTORY_DATA_TOTAL',
  setInventoryLoading: 'SET_INVENTORY_LOADER',
  setTwinInventoryLoading: 'SET_TWIN_INVENTORY_LOADER',
  setMatrixInventoryLoading: 'SET_MATRIX_INVENTORY_LOADER',
  setInventoryCartData: 'SET_INVENTORY_CART_DATA',
  setSingleInventoryData: 'SET_SINGLE_INVENTORY_DATA',
  setSearchParamsData: 'SET_SEARCH_PARAMS_DATA',
  setSearchTextData: 'SET_SEARCH_TEXT_DATA',
}

const initialInventoryState: IInventoryState = {
  inventoryData: undefined,
  inventoryDataTotal: undefined,
  twinInventoryData: {data: [], hasMore: false},
  twinInventoryDataTotal: undefined,
  matrixInventoryData: undefined,
  matrixInventoryDataTotal: undefined,
  inventoryLoading: false,
  twinInventoryLoading: false,
  matrixInventoryLoading: false,
  inventoryCartData: undefined,
  singleInventoryData: undefined,
  searchParamsData: {
    color_type: 'white',
  },
  searchTextData: undefined,
}

export interface IInventoryState {
  inventoryData?: InventoryModal
  inventoryDataTotal?: number
  twinInventoryData?: {data: any; hasMore: boolean}
  twinInventoryDataTotal?: number
  matrixInventoryData?: InventoryModal
  matrixInventoryDataTotal?: number
  inventoryLoading?: boolean
  twinInventoryLoading?: boolean
  matrixInventoryLoading?: boolean
  inventoryCartData?: []
  singleInventoryData?: []
  searchParamsData: any
  searchTextData: any
}

export const reducer = persistReducer(
  {
    storage,
    key: 'inventory',
    whitelist: [
      'inventoryData',
      'inventoryDataTotal',
      'inventoryCartData',
      // 'twinInventoryData',
      // 'twinInventoryDataTotal',
      'matrixInventoryData',
      'matrixInventoryDataTotal',
      'searchParamsData',
      'searchTextData',
    ],
  },
  (state: IInventoryState = initialInventoryState, action: ActionWithPayload<IInventoryState>) => {
    switch (action.type) {
      case actionTypes.setInventoryData: {
        const inventoryData = action.payload?.inventoryData
        return {...state, inventoryData}
      }

      case actionTypes.setInventoryDataTotal: {
        const inventoryDataTotal = action.payload?.inventoryDataTotal
        return {...state, inventoryDataTotal}
      }

      case actionTypes.setInventoryLoading: {
        const inventoryLoading = action.payload?.inventoryLoading
        return {...state, inventoryLoading}
      }

      case actionTypes.setTwinInventoryData: {
        const twinInventoryData = action.payload?.twinInventoryData
        return {...state, twinInventoryData}
      }

      case actionTypes.setMoreTwinInventoryData: {
        const twinInventoryData = action.payload?.twinInventoryData
        return {
          ...state,
          twinInventoryData: {
            data: [...state.twinInventoryData?.data, ...twinInventoryData?.data],
            hasMore: twinInventoryData?.data && twinInventoryData.data.length > 0,
          },
        }
      }

      case actionTypes.setTwinInventoryDataTotal: {
        const twinInventoryDataTotal = action.payload?.twinInventoryDataTotal
        return {...state, twinInventoryDataTotal}
      }

      case actionTypes.setTwinInventoryLoading: {
        const twinInventoryLoading = action.payload?.twinInventoryLoading
        return {...state, twinInventoryLoading}
      }

      case actionTypes.setMatrixInventoryData: {
        const matrixInventoryData = action.payload?.matrixInventoryData
        return {...state, matrixInventoryData}
      }

      case actionTypes.setMatrixInventoryDataTotal: {
        const matrixInventoryDataTotal = action.payload?.matrixInventoryDataTotal
        return {...state, matrixInventoryDataTotal}
      }

      case actionTypes.setMatrixInventoryLoading: {
        const matrixInventoryLoading = action.payload?.matrixInventoryLoading
        return {...state, matrixInventoryLoading}
      }

      case actionTypes.setInventoryCartData: {
        const inventoryCartData = action.payload?.inventoryCartData
        return {...state, inventoryCartData}
      }

      case actionTypes.setSingleInventoryData: {
        const singleInventoryData = action.payload?.singleInventoryData
        return {...state, singleInventoryData}
      }
      case actionTypes.setSearchParamsData: {
        const searchParamsData = action.payload?.searchParamsData
        return {...state, searchParamsData}
      }

      case actionTypes.setSearchTextData: {
        const searchTextData = action.payload?.searchTextData
        return {...state, searchTextData}
      }
      default:
        return state
    }
  }
)

export const actions = {
  setInventoryData: (inventoryData: Array<InventoryModal>) => ({
    type: actionTypes.setInventoryData,
    payload: {inventoryData: inventoryData},
  }),
  setInventoryDataTotal: (inventoryDataTotal: number) => ({
    type: actionTypes.setInventoryDataTotal,
    payload: {inventoryDataTotal},
  }),
  setInventoryLoading: (inventoryLoading: boolean) => ({
    type: actionTypes.setInventoryLoading,
    payload: {inventoryLoading},
  }),
  setTwinInventoryData: (twinInventoryData: any, hasMore: boolean) => ({
    type: actionTypes.setTwinInventoryData,
    payload: {twinInventoryData: {data: twinInventoryData, hasMore: hasMore}},
  }),
  setMoreTwinInventoryData: (twinInventoryData: any, hasMore: boolean) => ({
    type: actionTypes.setMoreTwinInventoryData,
    payload: {twinInventoryData: {data: twinInventoryData, hasMore: hasMore}},
  }),
  setTwinInventoryDataTotal: (twinInventoryDataTotal: number) => ({
    type: actionTypes.setTwinInventoryDataTotal,
    payload: {twinInventoryDataTotal},
  }),
  setTwinInventoryLoading: (twinInventoryLoading: boolean) => ({
    type: actionTypes.setTwinInventoryLoading,
    payload: {twinInventoryLoading},
  }),
  setMatrixInventoryData: (matrixInventoryData: Array<InventoryModal>) => ({
    type: actionTypes.setMatrixInventoryData,
    payload: {matrixInventoryData: matrixInventoryData},
  }),
  setMatrixInventoryDataTotal: (matrixInventoryDataTotal: number) => ({
    type: actionTypes.setMatrixInventoryDataTotal,
    payload: {matrixInventoryDataTotal},
  }),
  setMatrixInventoryLoading: (matrixInventoryLoading: boolean) => ({
    type: actionTypes.setMatrixInventoryLoading,
    payload: {matrixInventoryLoading},
  }),
  setInventoryCartData: (inventoryCartData: any) => ({
    type: actionTypes.setInventoryCartData,
    payload: {inventoryCartData: inventoryCartData},
  }),
  setSingleInventoryData: (singleInventoryData: any) => ({
    type: actionTypes.setSingleInventoryData,
    payload: {singleInventoryData: singleInventoryData},
  }),
  setSearchParamsData: (searechParams: any) => ({
    type: actionTypes.setSearchParamsData,
    payload: {searchParamsData: searechParams},
  }),
  setSearchTextData: (searchTextData: any) => ({
    type: actionTypes.setSearchTextData,
    payload: {searchTextData: searchTextData},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setInventoryData, function* loginSaga() {
    yield put(actions.setInventoryLoading(false))
  })
  yield takeLatest(actionTypes.setTwinInventoryData, function* loginSaga() {
    yield put(actions.setTwinInventoryLoading(false))
  })
  yield takeLatest(actionTypes.setMatrixInventoryData, function* loginSaga() {
    yield put(actions.setMatrixInventoryLoading(false))
  })
}
