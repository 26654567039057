/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ListWraaper} from './container/List'

export function ClientOfferPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/clientoffer/list' exact={true} component={ListWraaper} />
        <Redirect from='/clientoffer' exact={true} to='/clientoffer/list' />
        <Redirect to='/clientoffer/list' />
      </Switch>
    </div>
  )
}
