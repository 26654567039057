/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as vendororder from '../redux/OrderRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons'
import {getVendorOrderData} from '../redux/OrderCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
// import {PreviewOrderForm} from './PreviewOrderForm'
import moment from 'moment'
import {Tag} from 'antd'
import {returnPriceInThousandFormatWithCurrencySign, returnStockId} from '../../../comman/helper'
import {ChangeOrderStatusForm} from './ChangeOrderStatusForm'
import {ChangeOrderShipStatusForm} from './ChangeOrderShipStatusForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  tabkey: any
  vendorOrderData: any
  vendorOrderDataTotal: number
  vendorOrderLoading: boolean
  setListVendorOrderData: (vendorOrderData: any) => void
  setListVendorOrderDataTotal: (vendorOrderDataTotal: number) => void
  setVendorOrderLoading: (vendorOrderLoading: boolean) => void
  history?: History<LocationState>
  loginUser?: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  orderSelectedData: any
  orderShippedSelectedData: any
  dataTableSelectedRowKeys: any
  selectedRowsData: any
}

type PathParamsType = {
  param1: string
}
class ListOrderData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      orderSelectedData: null,
      orderShippedSelectedData: null,
      dataTableSelectedRowKeys: [],
      selectedRowsData: [],
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListOrderData(pagination)
    )
  }

  public getListOrderData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listOrderSearchData(start, end)
        } else {
          this.listOrderData(start, end)
        }
      }
    )
  }

  public listOrderData = async (start: number, end: any) => {
    const {setVendorOrderLoading} = this.props
    try {
      const {setListVendorOrderData, setListVendorOrderDataTotal, tabkey} = this.props
      await setVendorOrderLoading(true)
      let vendorOrderData = await getVendorOrderData(start, end, tabkey)
      if (vendorOrderData.data) {
        await setListVendorOrderData(vendorOrderData.data)
        await setListVendorOrderDataTotal(vendorOrderData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setVendorOrderLoading(false)
    }
  }

  public listOrderSearchData = async (start: number, end: any) => {
    const {setVendorOrderLoading} = this.props
    try {
      const {setListVendorOrderData, setListVendorOrderDataTotal, tabkey} = this.props
      const {searchText} = this.state

      await setVendorOrderLoading(true)
      let vendorOrderData = await getVendorOrderData(start, end, tabkey)
      if (vendorOrderData.data) {
        await setListVendorOrderData(vendorOrderData.data)
        await setListVendorOrderDataTotal(vendorOrderData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setVendorOrderLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listOrderSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateOrder = () => {
    const {history} = this.props
    history.push('/vendororder/create')
  }

  public showPreviewModal = async (item: any) => {
    this.setState({
      orderSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      orderSelectedData: null,
    })
    this.setState({
      dataTableSelectedRowKeys: [],
      selectedRowsData: [],
    })
    this.listOrderData(this.state.startIndex, this.state.limitNumber)
  }

  public showPreviewShippedModal = async (item: any) => {
    this.setState({
      orderShippedSelectedData: item,
    })
  }

  public closeShippedOrderModal = async () => {
    this.setState({
      orderShippedSelectedData: null,
    })
    this.setState({
      dataTableSelectedRowKeys: [],
      selectedRowsData: [],
    })
    this.listOrderData(this.state.startIndex, this.state.limitNumber)
  }

  public navigateToPreviewOrderPage = async (item: any) => {
    const {history} = this.props
    history.push(`${process.env.PUBLIC_URL}/vendor-order/preview/` + item.order_id)
  }

  counter = 0
  tempCounter = 0
  public vendorColumns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Order Code',
      key: 'order_code',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.order && item.order.order_code ? item.order.order_code : '-'}</strong>
          </div>
        )
      },
    },
    {
      // title: 'Stock Id',
      title: 'STOCK ID',
      key: 'Stock Id',
      width: 80,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <a href={`/inventory/preview/${item.stock_id}`} target='_blank'>
              <strong>
                {item.stock && item.stock.stock_id ? returnStockId(item.stock.stock_id) : '-'}
              </strong>
            </a>
          </div>
        )
      },
    },
    {
      title: 'Type',
      key: 'type',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.order && item.order.type ? (
              <Tag color={item.order.type == 'OFFER' ? 'green' : 'blue'}>{item.order.type}</Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    // {
    //   title: 'Company Name',
    //   key: 'company_operating_name',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         <strong>
    //           {item.order &&
    //           item.order.user_details &&
    //           item.order.user_details.company_operating_name
    //             ? item.order.user_details.company_operating_name
    //             : '-'}
    //         </strong>
    //       </div>
    //     )
    //   },
    // },
    // {
    //   title: 'Status',
    //   key: 'order_status',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         {item.order && item.order.order_status ? (
    //           <Tag
    //             color={
    //               config.statusTagColor[item.order.order_status]
    //                 ? config.statusTagColor[item.order.order_status]
    //                 : 'default'
    //             }
    //           >
    //             {item.order.order_status}
    //           </Tag>
    //         ) : (
    //           '-'
    //         )}
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Order Date',
      key: 'createdAt',
      sorter: (a: any, b:any) => {
        const dateA: any = new Date(a.createdAt);
        const dateB: any = new Date(b.createdAt);
        return dateA - dateB;
      },
      render: (text: any, item: any, index: number) => {
        return <div>{item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY') : '-'}</div>
      },
    },
    {
      title: 'Total Amount',
      key: 'amount',
      render: (text: any, item: any, index: number) => {
        return <div> {item.amount ? item.amount.toFixed(2) : '0'}</div>
      },
    },
    {
      title: 'Dollar Rate',
      key: 'dollar_rate',
      render: (text: any, item: any, index: number) => {
        return <div>{item.dollar_rate ? item.dollar_rate : '0'}</div>
      },
    },
    {
      // title: 'Shape',
      title: 'SHAPE',
      key: 'shape',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.shape ? returnStockId(item.stock.shape) : '-'}</div>
      },
    },
    // {
    //   // title: 'Weight',
    //   title: 'WEIGHT',
    //   key: 'weight',
    //   width: 75,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.stock && item.stock.weight ? item.stock.weight : '-'}</div>
    //   },
    // },
    {
      // title: 'Color',
      title: 'COLOR',
      key: 'color',
      // ellipsis: true,
      // width: 40,
      width: 65,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.color ? returnStockId(item.stock.color) : '-'}</div>
      },
    },
    {
      // title: 'Clarity',
      title: 'CLARITY',
      key: 'clarity',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.clarity ? returnStockId(item.stock.clarity) : '-'}</div>
        )
      },
    },
    {
      // title: 'Cut',
      title: 'CUT',
      key: 'cut',
      // width: 40,
      width: 45,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.cut ? returnStockId(item.stock.cut) : '-'}</div>
      },
    },
    {
      // title: 'Polish',
      title: 'POLISH',
      // ellipsis: true,
      key: 'polish',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.polish ? returnStockId(item.stock.polish) : '-'}</div>
      },
    },
    {
      // title: 'Symmetry',
      title: 'SYM',
      // ellipsis: true,
      key: 'symmetry',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.symmetry ? returnStockId(item.stock.symmetry) : '-'}</div>
        )
      },
    },
    {
      // title: 'Discounts',
      title: 'DISCOUNTS',
      // ellipsis: true,
      key: 'discounts',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.discounts ? item.stock.discounts : '-'}</div>
      },
    },
    {
      // title: 'Price Per Caret',
      title: '$/CT',
      // ellipsis: true,
      key: 'price_per_caret',
      width: 60,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.price_per_caret ? item.stock.price_per_caret : '-'}</div>
        )
      },
    },
    {
      // title: 'Final Price',
      title: 'FINAL PRICE',
      // ellipsis: true,
      key: 'final_price',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.stock && item.stock.final_price
              ? returnPriceInThousandFormatWithCurrencySign(item.stock.final_price)
              : '-'}
          </div>
        )
      },
    },
    // {
    //   title: 'Actions',
    //   key: 'action',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div style={{display: 'inline-block', width: '100%'}}>
    //         <div style={{float: 'left', marginRight: '5px'}}>
    //           <Tooltip placement='bottom' title='Preview'>
    //             <Button
    //               shape='circle'
    //               icon={<EyeOutlined />}
    //               onClick={() => {
    //                 // this.showPreviewModal(item)
    //                 this.navigateToPreviewOrderPage(item)
    //               }}
    //             />
    //           </Tooltip>
    //         </div>
    //       </div>
    //     )
    //   },
    // },
  ]

  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Company Name',
      key: 'legal_registered_name',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.order &&
              item.order.user_details &&
              item.order.user_details.legal_registered_name
                ? item.order.user_details.legal_registered_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Order Code',
      key: 'order_code',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.order && item.order.order_code ? item.order.order_code : '-'}</strong>
          </div>
        )
      },
    },
    {
      // title: 'Stock Id',
      title: 'STOCK ID',
      key: 'Stock Id',
      width: 80,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <a href={`/inventory/preview/${item.stock_id}`} target='_blank'>
              <strong>
                {item.stock && item.stock.stock_id ? returnStockId(item.stock.stock_id) : '-'}
              </strong>
            </a>
          </div>
        )
      },
    },
    {
      title: 'Vendor',
      key: 'vendor',
      width: 100,
      sorter: (a: any, b:any) => {
        const fullNameA = `${a.user_details?.first_name || ''} ${a.user_details?.last_name || ''}`.trim();
        const fullNameB = `${b.user_details?.first_name || ''} ${b.user_details?.last_name || ''}`.trim();
        return fullNameA.localeCompare(fullNameB);
      },
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.vendor && item?.vendor?.first_name && item?.vendor?.last_name
                ? `${item.vendor.first_name} ${item.vendor.last_name}`
                : item.vendor.first_name
                ? item.vendor.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Vendor Company',
      key: 'vendor',
      width: 120,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.vendor && item?.vendor?.company_name ? item.vendor.company_name : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Type',
      key: 'type',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.order && item.order.type ? (
              <Tag color={item.order.type == 'OFFER' ? 'green' : 'blue'}>{item.order.type}</Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Order Date',
      key: 'createdAt',
      width: 100,
      sorter: (a: any, b:any) => {
        const dateA: any = new Date(a.createdAt);
        const dateB: any = new Date(b.createdAt);
        return dateA - dateB;
      },
      render: (text: any, item: any, index: number) => {
        return <div>{item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY') : '-'}</div>
      },
    },
    {
      title: 'Total Amount',
      key: 'amount',
      render: (text: any, item: any, index: number) => {
        return <div> {item.amount ? returnPriceInThousandFormatWithCurrencySign(item.amount.toFixed(2)) : '0'}</div>
      },
    },
    {
      title: 'Dollar Rate',
      key: 'dollar_rate',
      render: (text: any, item: any, index: number) => {
        return <div>{item.dollar_rate ? item.dollar_rate : '0'}</div>
      },
    },
    {
      // title: 'Shape',
      title: 'SHAPE',
      key: 'shape',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.shape ? returnStockId(item.stock.shape) : '-'}</div>
      },
    },
    // {
    //   // title: 'Weight',
    //   title: 'WEIGHT',
    //   key: 'weight',
    //   width: 75,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.stock && item.stock.weight ? item.stock.weight : '-'}</div>
    //   },
    // },
    {
      // title: 'Color',
      title: 'COLOR',
      key: 'color',
      // ellipsis: true,
      // width: 40,
      width: 65,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.color ? returnStockId(item.stock.color) : '-'}</div>
      },
    },
    {
      // title: 'Clarity',
      title: 'CLARITY',
      key: 'clarity',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.clarity ? returnStockId(item.stock.clarity) : '-'}</div>
        )
      },
    },
    {
      // title: 'Cut',
      title: 'CUT',
      key: 'cut',
      // width: 40,
      width: 45,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.cut ? returnStockId(item.stock.cut) : '-'}</div>
      },
    },
    {
      // title: 'Polish',
      title: 'POLISH',
      // ellipsis: true,
      key: 'polish',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.polish ? returnStockId(item.stock.polish) : '-'}</div>
      },
    },
    {
      // title: 'Symmetry',
      title: 'SYM',
      // ellipsis: true,
      key: 'symmetry',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.symmetry ? returnStockId(item.stock.symmetry) : '-'}</div>
        )
      },
    },
    {
      // title: 'Discounts',
      title: 'DISCOUNTS',
      // ellipsis: true,
      key: 'discounts',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.discounts ? item.stock.discounts : '-'}</div>
      },
    },
    {
      // title: 'Price Per Caret',
      title: '$/CT',
      // ellipsis: true,
      key: 'price_per_caret',
      width: 60,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.price_per_caret ? returnPriceInThousandFormatWithCurrencySign(item.stock.price_per_caret) : '-'}</div>
        )
      },
    },
    {
      // title: 'Final Price',
      title: 'FINAL PRICE',
      // ellipsis: true,
      key: 'final_price',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.stock && item.stock.final_price
              ? returnPriceInThousandFormatWithCurrencySign(item.stock.final_price)
              : '-'}
          </div>
        )
      },
    },
    // {
    //   title: 'Actions',
    //   key: 'action',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div style={{display: 'inline-block', width: '100%'}}>
    //         <div style={{float: 'left', marginRight: '5px'}}>
    //           <Tooltip placement='bottom' title='Edit'>
    //             <Button
    //               shape='circle'
    //               icon={<EyeOutlined />}
    //               onClick={() => {
    //                 this.showPreviewModal(item)
    //                 // this.navigateToPreviewOrderPage(item)
    //               }}
    //             />
    //           </Tooltip>
    //         </div>
    //       </div>
    //     )
    //   },
    // },
  ]

  rowSelection = {
    onChange: async (selectedRowKeys: any, selectedRows: any, selected: any) => {
      this.setState({dataTableSelectedRowKeys: selectedRowKeys, selectedRowsData: selectedRows})
    },
  }

  public render() {
    const {vendorOrderData, vendorOrderDataTotal, vendorOrderLoading, loginUser, tabkey} =
      this.props
    const {role} = loginUser
    const {
      searchText,
      orderSelectedData,
      orderShippedSelectedData,
      dataTableSelectedRowKeys,
      selectedRowsData,
    } = this.state

    const columnExists = (key: any) => {
      return this.columns.some((column) => column.key === key)
    }

    if (!columnExists('vendor_margin') && role == 'super_admin') {
      this.columns.splice(7, 0, {
        title: 'Vendor Margin',
        key: 'vendor_margin',
        render: (text: any, item: any, index: number) => {
          return (
            <div>
              <strong>
                {item.stock_offer && item.stock_offer.vendor_margin
                  ? item.stock_offer.vendor_margin
                  : '-'}
              </strong>
            </div>
          )
        },
      })
    }

    return (
      <div>
        <div>
          {(role == 'super_admin' || role == 'sub_admin') &&
            tabkey == 'PENDING' &&
            dataTableSelectedRowKeys &&
            !!dataTableSelectedRowKeys.length && (
              <div className='row justify-content-end' style={{marginRight: '20px'}}>
                <Button
                  className='m-5 rounded'
                  style={{width: '100px'}}
                  type='primary'
                  onClick={this.showPreviewModal.bind(this)}
                >
                  UPDATE
                </Button>
              </div>
            )}
          {(role == 'super_admin' || role == 'sub_admin') &&
            tabkey == 'SHIPPED' &&
            dataTableSelectedRowKeys &&
            !!dataTableSelectedRowKeys.length && (
              <div className='row justify-content-end' style={{marginRight: '20px'}}>
                <Button
                  className='m-5 rounded'
                  style={{width: '100px'}}
                  type='primary'
                  onClick={this.showPreviewShippedModal.bind(this)}
                >
                  UPDATE
                </Button>
              </div>
            )}
          <div className='card card-custom'>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {id: any}) => record.id}
                  onChange={this.handleTableChange}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  column={role == 'vendor' ? this.vendorColumns : this.columns}
                  paginationConfig={{
                    total: vendorOrderDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={vendorOrderLoading}
                  dataSource={vendorOrderData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={true}
                  scrollWidth={loginUser?.role === 'vendor' ? 1500 : 1700}
                  rowSelection={
                    loginUser?.role !== 'vendor' && tabkey !== 'PAID' &&  tabkey !== 'CANCELED'
                      ? {
                          type: 'checkbox',
                          ...this.rowSelection,
                          selectedRowKeys: dataTableSelectedRowKeys ? dataTableSelectedRowKeys : [],
                        }
                      : undefined
                  }
                />
                <div>
                  <CommanModal
                    show={orderSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Order Status'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {orderSelectedData && (
                        <ChangeOrderStatusForm
                          data={selectedRowsData}
                          rowIds={dataTableSelectedRowKeys}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                  <CommanModal
                    show={orderShippedSelectedData ? true : false}
                    handleClose={() => {
                      this.closeShippedOrderModal()
                    }}
                    title={'Order Status'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {orderShippedSelectedData && (
                        <ChangeOrderShipStatusForm
                          data={selectedRowsData}
                          rowIds={dataTableSelectedRowKeys}
                          handleCancel={() => {
                            this.closeShippedOrderModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  vendorOrderData: state.vendororder.vendorOrderData,
  vendorOrderDataTotal: state.vendororder.vendorOrderDataTotal,
  vendorOrderLoading: state.vendororder.vendorOrderLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListVendorOrderData: (vendorOrderData: any) => {
      dispatch(vendororder.actions.setVendorOrderData(vendorOrderData))
    },
    setListVendorOrderDataTotal: (vendorOrderDataTotal: number) => {
      dispatch(vendororder.actions.setVendorOrderDataTotal(vendorOrderDataTotal))
    },
    setVendorOrderLoading: (vendorOrderLoading: boolean) => {
      dispatch(vendororder.actions.setVendorOrderLoading(vendorOrderLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListOrderData))
