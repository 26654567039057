import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setDeclineStockData: 'SET_DECLINE_STOCK_DATA',
  setDeclineStockDataTotal: 'SET_DECLINE_STOCK_DATA_TOTAL',
  setDeclineStockLoading: 'SET_DECLINE_STOCK_LOADER',
}

const initialOrderState: IDeclineStockState = {
  declineStockData: undefined,
  declineStockDataTotal: undefined,
  declineStockLoading: false,
}

export interface IDeclineStockState {
  declineStockData?: any
  declineStockDataTotal?: number
  declineStockLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'declinestock', whitelist: ['declineStockData', 'declineStockDataTotal']},
  (state: IDeclineStockState = initialOrderState, action: ActionWithPayload<IDeclineStockState>) => {
    switch (action.type) {
      case actionTypes.setDeclineStockData: {
        const declineStockData = action.payload?.declineStockData
        return {...state, declineStockData}
      }

      case actionTypes.setDeclineStockDataTotal: {
        const declineStockDataTotal = action.payload?.declineStockDataTotal
        return {...state, declineStockDataTotal}
      }

      case actionTypes.setDeclineStockLoading: {
        const declineStockLoading = action.payload?.declineStockLoading
        return {...state, declineStockLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setDeclineStockData: (declineStockData: Array<any>) => ({
    type: actionTypes.setDeclineStockData,
    payload: {declineStockData: declineStockData},
  }),
  setDeclineStockDataTotal: (declineStockDataTotal: number) => ({
    type: actionTypes.setDeclineStockDataTotal,
    payload: {declineStockDataTotal},
  }),
  setDeclineStockLoading: (declineStockLoading: boolean) => ({
    type: actionTypes.setDeclineStockLoading,
    payload: {declineStockLoading},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setDeclineStockData, function* loginSaga() {
    yield put(actions.setDeclineStockLoading(false))
  })
}
