import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setStaticData: 'SET_STATIC_DATA',
  setStaticDataTotal: 'SET_STATIC_DATA_TOTAL',
  setStaticLoading: 'SET_STATIC_LOADER'
}

const initialStaticState: IStaticState = {
  staticData: undefined,
  staticDataTotal: undefined,
  staticLoading: false
}

export interface IStaticState {
  staticData?: any
  staticDataTotal?: number
  staticLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'staticpage', whitelist: ['staticData', 'staticDataTotal']},
    (state: IStaticState = initialStaticState, action: ActionWithPayload<IStaticState>) => {
    switch (action.type) {
      case actionTypes.setStaticData: {
        const staticData = action.payload?.staticData
        return {...state, staticData}
      }
            
      case actionTypes.setStaticDataTotal: {
        const staticDataTotal = action.payload?.staticDataTotal
        return {...state, staticDataTotal}
      }
        
      case actionTypes.setStaticLoading: {
        const staticLoading = action.payload?.staticLoading
        return {...state, staticLoading}
      }


      default:
        return state
    }
  }
)

export const actions = {
  setStaticData: (staticData: Array<any>) => ({type: actionTypes.setStaticData, payload: {staticData : staticData}}),
  setStaticDataTotal: (staticDataTotal: number) => ({ type: actionTypes.setStaticDataTotal, payload: { staticDataTotal } }),
  setStaticLoading: (staticLoading: boolean) => ({type: actionTypes.setStaticLoading, payload: {staticLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setStaticData, function* loginSaga() {
    yield put(actions.setStaticLoading(false))
  })
}
