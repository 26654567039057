import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setSubAdminData: 'SET_SUBADMIN_DATA',
  setSubAdminDataTotal: 'SET_SUBADMIN_DATA_TOTAL',
  setSubAdminLoading: 'SET_SUBADMIN_LOADER'
}

const initialAdminState: IAdminState = {
  subAdminData: undefined,
  subAdminDataTotal: undefined,
  subAdminLoading: false
}

export interface IAdminState {
  subAdminData?: any
  subAdminDataTotal?: number
  subAdminLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'subadmin', whitelist: ['subAdminData', 'subAdminDataTotal']},
    (state: IAdminState = initialAdminState, action: ActionWithPayload<IAdminState>) => {
    switch (action.type) {
      case actionTypes.setSubAdminData: {
        const subAdminData = action.payload?.subAdminData
        return {...state, subAdminData}
      }
            
      case actionTypes.setSubAdminDataTotal: {
        const subAdminDataTotal = action.payload?.subAdminDataTotal
        return {...state, subAdminDataTotal}
      }
        
      case actionTypes.setSubAdminLoading: {
        const subAdminLoading = action.payload?.subAdminLoading
        return {...state, subAdminLoading}
      }


      default:
        return state
    }
  }
)

export const actions = {
  setSubAdminData: (subAdminData: Array<any>) => ({type: actionTypes.setSubAdminData, payload: {subAdminData : subAdminData}}),
  setSubAdminDataTotal: (subAdminDataTotal: number) => ({ type: actionTypes.setSubAdminDataTotal, payload: { subAdminDataTotal } }),
  setSubAdminLoading: (subAdminLoading: boolean) => ({type: actionTypes.setSubAdminLoading, payload: {subAdminLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setSubAdminData, function* loginSaga() {
    yield put(actions.setSubAdminLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
