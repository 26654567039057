import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setVendorsData: 'SET_VENDORS_DATA',
  setVendorsDataTotal: 'SET_VENDORS_DATA_TOTAL',
  setVendorsLoading: 'SET_VENDORS_LOADER'
}

const initialClientState: IVendorsState = {
  vendorsData: undefined,
  vendorsDataTotal: undefined,
  vendorsLoading: false
}

export interface IVendorsState {
  vendorsData?: any
  vendorsDataTotal?: number
  vendorsLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'vendors', whitelist: ['vendorsData', 'vendorsDataTotal']},
    (state: IVendorsState = initialClientState, action: ActionWithPayload<IVendorsState>) => {
    switch (action.type) {
      case actionTypes.setVendorsData: {
        const vendorsData = action.payload?.vendorsData
        return {...state, vendorsData}
      }
            
      case actionTypes.setVendorsDataTotal: {
        const vendorsDataTotal = action.payload?.vendorsDataTotal
        return {...state, vendorsDataTotal}
      }
        
      case actionTypes.setVendorsLoading: {
        const vendorsLoading = action.payload?.vendorsLoading
        return {...state, vendorsLoading}
      }


      default:
        return state
    }
  }
)

export const actions = {
  setVendorsData: (vendorsData: Array<any>) => ({type: actionTypes.setVendorsData, payload: {vendorsData : vendorsData}}),
  setVendorsDataTotal: (vendorsDataTotal: number) => ({ type: actionTypes.setVendorsDataTotal, payload: { vendorsDataTotal } }),
  setVendorsLoading: (vendorsLoading: boolean) => ({type: actionTypes.setVendorsLoading, payload: {vendorsLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setVendorsData, function* loginSaga() {
    yield put(actions.setVendorsLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
