/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getDeclineStockData} from '../redux/DeclineStockCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as declinestock from '../redux/DeclineStockRedux'
import ListDeclineStockData from './ListDeclineStockData'
import {RootState} from '../../../../setup'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const callDeclineStockData = async () => {
    try {
      let declinestockData: any = await getDeclineStockData(0, 10)

      if (declinestockData.data) {
        await dispatch(declinestock.actions.setDeclineStockLoading(true))
        // once declinestock data set then loading will automatically off using saga middleware
        await dispatch(declinestock.actions.setDeclineStockData(declinestockData.data))
        await dispatch(declinestock.actions.setDeclineStockDataTotal(declinestockData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callDeclineStockData()
  }, [])

  return (
    <div>
      <ListDeclineStockData />
    </div>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/decline-stock/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/decline-stock/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.DECLINESTOCKS'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
