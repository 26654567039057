/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  console.log(user, 'admin')
  const {role, permission} = user
  return (
    <>
      {/* Dashboard module */}
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {/* Sub Admin module */}
      {role === 'super_admin' && (
        <AsideMenuItemWithSub
          to='/subadmin'
          title={intl.formatMessage({id: 'MENU.SUBADMIN'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/subadmin/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/subadmin/create'
            title={intl.formatMessage({id: 'MENU.CREATE'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}

      {/* Users module */}
      {role === 'super_admin' || (role === 'sub_admin' && permission?.user) ? (
        <AsideMenuItemWithSub
          to='/users'
          title={intl.formatMessage({id: 'MENU.CLIENT'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/users/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
          {/* <AsideMenuItem
              to='/users/create'
              title={intl.formatMessage({id: 'MENU.CREATE'})}
              hasBullet={true}
            /> */}
        </AsideMenuItemWithSub>
      ) : null}

      {/* Vendors module */}
      {role === 'super_admin' || (role === 'sub_admin' && permission?.vendors) ? (
        <AsideMenuItemWithSub
          to='/vendors'
          title={intl.formatMessage({id: 'MENU.VENDOR'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/vendors/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/vendors/create'
            title={intl.formatMessage({id: 'MENU.CREATE'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {/* Inventory module */}
      {role === 'super_admin' ||
      (role === 'sub_admin' && permission?.list_inventory) ||
      role === 'vendor' ? (
        <AsideMenuItemWithSub
          to='/inventory'
          title={intl.formatMessage({id: 'MENU.INVENTORY'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/inventory/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
          {/* Subadmin cannot import inventory */}
          {role !== 'sub_admin' && (
            <AsideMenuItem
              to='/inventory/import'
              title={intl.formatMessage({id: 'MENU.IMPORTINVENTORY'})}
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      ) : null}

      {role === 'super_admin' || (role === 'sub_admin' && permission?.buyrequest) ? (
        <AsideMenuItemWithSub
          to='/buy-request'
          title={intl.formatMessage({id: 'MENU.BUYREQUEST'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/buy-request/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {role === 'super_admin' || (role === 'sub_admin' && permission?.order) ? (
        <AsideMenuItemWithSub
          to='/order'
          title={intl.formatMessage({id: 'MENU.ORDER'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/order/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {role === 'super_admin' || (role === 'sub_admin' && permission?.vendororder) ? (
        <AsideMenuItemWithSub
          to='/vendor-order'
          title={intl.formatMessage({id: 'MENU.VENDORORDERADMIN'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/vendor-order/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {role === 'super_admin' ||
      (role === 'sub_admin' && permission && permission.return_order) ||
      role === 'vendor' ? (
        <AsideMenuItemWithSub
          to='/returnorder'
          title={intl.formatMessage({id: 'MENU.RETURNORDER'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/returnorder/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {role === 'super_admin' ||
      (role === 'sub_admin' && permission && permission.stock_offer) ||
      role === 'vendor' ? (
        <AsideMenuItemWithSub
          to='/clientoffer'
          title={intl.formatMessage({id: 'MENU.CLIENTOFFER'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/clientoffer/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {role === 'super_admin' || (role === 'sub_admin' && permission?.banner) ? (
        <AsideMenuItemWithSub
          to='/banner'
          title={intl.formatMessage({id: 'MENU.BANNER'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/banner/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/banner/create'
            title={intl.formatMessage({id: 'MENU.CREATE'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {role === 'super_admin' || (role === 'sub_admin' && permission?.kyc) ? (
        <AsideMenuItemWithSub
          to='/kyc'
          title={intl.formatMessage({id: 'MENU.KYC'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/kyc/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {role === 'super_admin' || (role === 'sub_admin' && permission?.staticpage) ? (
        <AsideMenuItemWithSub
          to='/static'
          title={intl.formatMessage({id: 'MENU.STATICPAGE'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/static/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/static/create'
            title={intl.formatMessage({id: 'MENU.CREATE'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {role === 'vendor' ? (
        <>
          {/* <AsideMenuItemWithSub
            to='/buy-request'
            title={intl.formatMessage({id: 'MENU.BUYREQUEST'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/buy-request/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub> */}
          <AsideMenuItemWithSub
            to='/vendor-order'
            title={intl.formatMessage({id: 'MENU.VENDORORDER'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/vendor-order/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/vendor-stock'
            title={intl.formatMessage({id: 'MENU.STOCKREQUEST'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/vendor-stock/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </>
      ) : null}
      {/* Sub Admin module */}
      {role === 'super_admin' && (
        <>
        <AsideMenuItemWithSub
          to='/chats'
          title={intl.formatMessage({id: 'MENU.CHATS'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/chats/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to='/sales-report'
          title={intl.formatMessage({id: 'MENU.SALESREPORT'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/sales-report/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to='/decline-stock'
          title={intl.formatMessage({id: 'MENU.DECLINESTOCKS'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/decline-stock/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        </>
      )}
    </>
  )
}
