import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setReturnOrderData: 'SET_RETURN_ORDER_DATA',
  setReturnOrderDataTotal: 'SET_RETURN_ORDER_DATA_TOTAL',
  setReturnOrderLoading: 'SET_RETURN_ORDER_LOADER'
}

const initialOrderState: IOrderState = {
  returnOrderData: undefined,
  returnOrderDataTotal: undefined,
  returnOrderLoading: false
}

export interface IOrderState {
  returnOrderData?: any
  returnOrderDataTotal?: number
  returnOrderLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'returnorder', whitelist: ['returnOrderData', 'returnOrderDataTotal']},
    (state: IOrderState = initialOrderState, action: ActionWithPayload<IOrderState>) => {
    switch (action.type) {
      case actionTypes.setReturnOrderData: {
        const returnOrderData = action.payload?.returnOrderData
        return {...state, returnOrderData}
      }
            
      case actionTypes.setReturnOrderDataTotal: {
        const returnOrderDataTotal = action.payload?.returnOrderDataTotal
        return {...state, returnOrderDataTotal}
      }
        
      case actionTypes.setReturnOrderLoading: {
        const returnOrderLoading = action.payload?.returnOrderLoading
        return {...state, returnOrderLoading}
      }


      default:
        return state
    }
  }
)

export const actions = {
  setReturnOrderData: (returnOrderData: Array<any>) => ({type: actionTypes.setReturnOrderData, payload: {returnOrderData : returnOrderData}}),
  setReturnOrderDataTotal: (returnOrderDataTotal: number) => ({ type: actionTypes.setReturnOrderDataTotal, payload: { returnOrderDataTotal } }),
  setReturnOrderLoading: (returnOrderLoading: boolean) => ({type: actionTypes.setReturnOrderLoading, payload: {returnOrderLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setReturnOrderData, function* loginSaga() {
    yield put(actions.setReturnOrderLoading(false))
  })
}
