import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setSalesReportData: 'SET_SALES_REPORT_DATA',
  setSalesReportDataTotal: 'SET_SALES_REPORT_DATA_TOTAL',
  setSalesReportLoading: 'SET_SALES_REPORT_LOADER',
  setTotalSalesData: 'SET_TOTAL_SALES',
  setStartDate: 'SET_START_DATE',
  setEndDate: 'SET_END_DATE',

}

const initialOrderState: ISalesState = {
  salesReportData: undefined,
  salesReportDataTotal: undefined,
  salesReportLoading: false,
  startDate: undefined,
  endDate: undefined,
  totalSalesData: undefined
}

export interface ISalesState {
  salesReportData?: any
  salesReportDataTotal?: number
  salesReportLoading?: boolean
  startDate: any
  endDate: any
  totalSalesData: any
}

export const reducer = persistReducer(
  {storage, key: 'sales', whitelist: ['salesReportData', 'salesReportDataTotal']},
  (state: ISalesState = initialOrderState, action: ActionWithPayload<ISalesState>) => {
    switch (action.type) {
      case actionTypes.setSalesReportData: {
        const salesReportData = action.payload?.salesReportData
        return {...state, salesReportData}
      }

      case actionTypes.setSalesReportDataTotal: {
        const salesReportDataTotal = action.payload?.salesReportDataTotal
        return {...state, salesReportDataTotal}
      }

      case actionTypes.setSalesReportLoading: {
        const salesReportLoading = action.payload?.salesReportLoading
        return {...state, salesReportLoading}
      }

      case actionTypes.setStartDate: {
        const startDate = action.payload?.startDate
        return {...state, startDate}
      }

      case actionTypes.setEndDate: {
        const endDate = action.payload?.endDate
        return {...state, endDate}
      }

      case actionTypes.setTotalSalesData: {
        const totalSalesData = action.payload?.totalSalesData
        return {...state, totalSalesData}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setSalesReportData: (salesReportData: Array<any>) => ({
    type: actionTypes.setSalesReportData,
    payload: {salesReportData: salesReportData},
  }),
  setSalesReportDataTotal: (salesReportDataTotal: number) => ({
    type: actionTypes.setSalesReportDataTotal,
    payload: {salesReportDataTotal},
  }),
  setSalesReportLoading: (salesReportLoading: boolean) => ({
    type: actionTypes.setSalesReportLoading,
    payload: {salesReportLoading},
  }),
  setStartDate: (startDate: any) => ({
    type: actionTypes.setStartDate,
    payload: {startDate},
  }),
  setEndDate: (endDate: any) => ({
    type: actionTypes.setEndDate,
    payload: {endDate},
  }),
  setTotalSalesData: (totalSalesData: any) => ({
    type: actionTypes.setTotalSalesData,
    payload: {totalSalesData},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setSalesReportData, function* loginSaga() {
    yield put(actions.setSalesReportLoading(false))
  })
}
